import React from 'react';

import './TermsOfPurchase.scss';
import { Link } from 'react-router-dom';

function TermsOfPurchasePT() {
  return (
    <div className='bg-terms-of-purchase'>
      <h5>Introduction:</h5>

      <ul>
        <li>
          The term(s) “Event(s)” refer to leisure activities organized by Parque Bondinho Pão de Açúcar, including access to the Park and Cable Car, music shows, sports events, festivals, exhibitions, or any other cultural or entertainment content that requires an access pass for entry.
        </li>
        <li>
          The term(s) “Ticket(s)” refer to the right of access to the event(s) or service(s), the Park, or the Cable Car. This access may be granted via paper, digital formats, or other methods containing a validation system, such as barcodes, biometrics, or QR codes, issued exclusively by Parque Bondinho Pão de Açúcar and sold or distributed through our digital platform or physical ticket offices.
        </li>
        <li>
          The term(s) “User(s)” refer to any person who: (i) registers on the Parque Bondinho Pão de Açúcar platform, after acknowledging and agreeing to the Terms of Use, to access any available tools; (ii) purchases Tickets/Products/Packages/Experiences, or other products or services through any available sales channel, including the Official Ticket Office (physical sales); or (iii) visitors in general.
        </li>
        <li>
          The term(s) “Package(s)” refer to bundles offered for sale, including a Ticket and one or more additional products or services, such as Guided Tours, among others.
        </li>
        <li>
          The term(s) “Package(s)” refer to bundles offered for sale, including a Ticket and one or more additional products or services, such as Guided Tours, among others.
        </li>
        <li>
          The term(s) “Voucher(s)” refer to purchase confirmations for products, services, or experiences.
        </li>
        <li>
          This “Purchase Policy” establishes the terms and conditions applicable to Ticket purchases.
        </li>
      </ul>

      <h6>1.	Legal Binding Contract:</h6>

      <p>1.	This Purchase Policy applies to all products and services offered for sale by us.</p>

      <p>1.1.	To purchase a Ticket/Product/Package/Experience, whether at the Ticket Office or through our online platform, the User must be at least 18 (eighteen) years old and capable of entering legally binding contracts. If the User makes an online purchase, they must also have an account on the website <Link to="/">www.bondinho.com.br</Link>, which is likewise subject to the Terms of Use.</p>
      <p>1.2.	Any purchase made on our platform (physical or online) constitutes a legally binding contract subject to: (i) this Purchase Policy; (ii) any specific terms and conditions declared applicable to a specific Item and/or Event; (iii) additional terms and conditions of the Partner(s) for Events taking place at Parque Bondinho Pão de Açúcar. Users must carefully read this Purchase Policy before making a purchase.</p>
      <p>1.3.	By purchasing one or more Items on our platform (physical or online), the User acknowledges and agrees to be bound by the terms and conditions of this Purchase Policy. If the User disagrees with this Purchase Policy or any applicable terms and conditions or cannot comply with any of them, they should not make a purchase.</p>
      <p>1.4.	We reserve the right to make changes to this Purchase Policy periodically. Whenever such changes are made, we will publish the updated version of this Purchase Policy on our website. Therefore, we recommend that the User regularly reviews this Purchase Policy to stay informed of the current terms and conditions. All purchases are subject to the version of this Purchase Policy that was applicable at the time of purchase. If the User disagrees with any revised version of this Purchase Policy or cannot comply with it, they should not make a purchase.</p>
      <p>1.5.	The Ticket/Product/Package/Experience sales website belongs to Parque Bondinho Pão de Açúcar Tecnologia Eletrônica Ltda., which has licensed its use to Companhia Caminho Aéreo Pão de Açúcar.</p>
      <p>1.6.	The sales platform for Ticket/Product/Package/Experience is owned by Parque Bondinho Pão de Açúcar Tecnologia Eletrônica Ltda., a service provider for Companhia Caminho Aéreo Pão de Açúcar.</p>
      <p>1.7.	Parque Bondinho Pão de Açúcar provides ticket sales and event promotion services but is not involved in the production or organization of the tours promoted on the website.</p>
      <p>1.8.	Purchase and cancellation rules are established by Companhia Caminho Aéreo Pão de Açúcar.</p>

      <h6>2.	Prices, Payments, and Orders:</h6>

      <p>2.1.	All prices for Tickets, Packages, Products, and Experiences sold through our official channels include applicable taxes as required by current legislation.</p>
      <p>2.2.	Accepted payment methods include debit cards and cash (for in-person purchases only), credit cards (Visa, Elo, Mastercard, Diners, Amex, Hipercard, Discover, JCB), and PIX transfers.</p>
      <p>2.3.	All purchases are subject to verification of the credit or debit card (if applicable) or other security checks, and additional documentation may be requested to finalize your order. Orders may be canceled if they do not pass our verification process or if full payment is not received. In rare cases, if the payment is canceled by the bank or associated payment provider, we reserve the right to cancel and refund any order for which a confirmation has already been sent.</p>
      <p>2.4.	We reserve the right to cancel any orders we reasonably suspect to have been made fraudulently, without notice to the User, and any Items obtained as part of such orders will be invalidated. Please ensure you read the full details of the item description and verify your selection before making a purchase.</p>
      <p>2.5. Purchases are subject to the availability of Tickets/Products/Packages/Experiences and the approval of your credit card operator or the clearance of bank slips, which may take up to 5 (five) days. The customer will only receive confirmation of their booking once payment has been successfully processed.</p>

      <h6>3.	Delivery:</h6>

      <p>3.1. If the purchase is made through our website (<Link to="/">www.bondinho.com.br</Link>) and the online delivery option for the Ticket/Product/Package/Experience is available, the User is responsible for: (i) ensuring they log into their account and download their Tickets/Vouchers in a timely manner before the boarding/event; or (ii) providing a valid email address for online delivery of Tickets/Vouchers and ensuring they can receive the delivery via email (e.g., making sure their email inbox does not block, return, or prevent any email from being delivered). The User must also regularly check their email inbox—including junk and SPAM folders.</p>
      <p>3.2.	The User will not be entitled to any refund if the Ticket/Voucher is lost, not redeemed, not received in their email inbox, or if they fail to download the Tickets/Vouchers from their account on our website (<Link to="/">www.bondinho.com.br</Link>) due to their failure to provide accurate and complete delivery information and/or to secure possession of the Ticket/Voucher.</p>
      <p>3.3.	The User must contact us through our Customer Service Center (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br</Link>) immediately if there is any mistake or error with their Ticket/Voucher, or if they do not receive their Tickets/Vouchers as requested and/or as described in the order confirmation.</p>

      <h6>4.	Rights and Obligations of the User:</h6>

      <p>4.1.	Any purchase of a Ticket/Product/Package/Experience through our official channels (website <Link to="/">www.bondinho.com.br</Link> or physical Ticket Office) grants a personal license for access to the event/location, which may be canceled under the terms of this document.</p>
      <p>4.2. Upon receiving or collecting your Tickets/Vouchers, keep them in a safe place. We are not responsible for any Tickets/Vouchers that are lost, stolen, misplaced, damaged, or destroyed after they have been delivered to the User. Exposure to sunlight or heat may damage physical tickets/vouchers. Additionally, we recommend that the User does not post photos or details of their Tickets/Vouchers online, as this could enable third parties to counterfeit them or compromise their integrity. We will not be responsible if your entry is denied as a result.</p>
      <p>4.3.	Carefully review the details of your order (scheduling, date, time, quantities) before confirming your purchase. Exchanges of your ticket/voucher for another scheduled date will not be allowed, except as provided in the Cancellation Policy (Clause 9). Your Ticket/Product/Package/Experience is a unique product, meaning that once purchased, it will no longer be available for sale.</p>
      <p>4.4.	For purchases made using a third-party credit card, it is mandatory to present a copy of the official photo ID of the credit cardholder used for the purchase and a copy of the front of the card only. Failure to present these documents may result in entry being denied.</p>
      <p>4.5.	For online purchases of Tickets/Products/Packages/Experiences, you can present your Ticket/Voucher in paper format (by printing it) or in digital format (via your mobile phone or tablet, for example). These documents will contain a QR Code that will be scanned at the turnstiles.</p>
      <p>4.6.	Tickets and/or Vouchers obtained from unauthorized sources may be invalid, lost, stolen, or counterfeit. Parque Bondinho Pão de Açúcar reserves the right to revoke, invalidate, or cancel such items without entitlement to any refund.</p>
      <p>4.7.	Parque Bondinho Pão de Açúcar will provide support exclusively to the User, meaning the individual or legal entity duly registered, and only for purchases proven to have been made through the User’s account on the Ticket/Product/Package/Experience sales website or by the purchaser of a physical ticket/voucher in the case of purchases made at the ticket office.</p>
      <p>4.8.	Any violation of the Park’s Terms and Conditions or Access Rules, as well as any unacceptable behavior that may cause damage, inconvenience, or injury, grants Parque Bondinho Pão de Açúcar the right to expel the User from the premises.</p>

      <h6>5.	Ticket/Voucher Restrictions:</h6>

      <p>5.1.	Tickets/Vouchers may be sold subject to certain entry or usage restrictions, such as minimum age requirements. Any restrictions will be displayed or communicated to the User before or at the time of booking. It is the User’s responsibility to review such important information displayed during the purchase process. Parque Bondinho Pão de Açúcar will not be held liable if the User or any guest in their booking is denied admission due to failure to meet or prove compliance with any restrictions (e.g., a minimum age requirement).</p>
      <p>5.2.	Discount Rules as outlined on the website <Link to="/">www.bondinho.com.br</Link> must be observed at the time of purchase by the User.</p>
      <p>5.3.	Half-Price Admission and Free Tickets: Individuals purchasing half-price tickets must proceed to the Ticket Office for verification of original/printed documents validating their eligibility for the benefit. Identification wristbands will be issued upon validation.</p>

      <h6>6.	Event Times and Access to the Park:</h6>

      <p>6.1.	Please note that the start times of Events or services announced—such as Guided Tours, Breakfast, and others—are subject to change.</p>
      <p>6.2.	By participating in an Event, the User agrees to be photographed, filmed, and/or recorded in connection with the Event and/or for safety and security purposes, including recording by law enforcement. Furthermore, the User agrees that the resulting photographs, videos, audio recordings, and/or audiovisual recordings may be used in all media for any purpose at any time worldwide by Parque Bondinho Pão de Açúcar and its partners.</p>

      <h6>7.	Cancellation and Changes to Events/Services by Parque Bondinho Pão de Açúcar®:</h6>

      <p>7.1.	If an Event or Service is canceled, rescheduled, or substantially altered, we will make every reasonable effort to disclose this information. However, we cannot guarantee that the User will be informed of such changes, as they may occur at any time, including due to a public authority decision, unforeseen circumstances, and/or force majeure.</p>
      <p>7.2.	If an Event for which the User has purchased Tickets/Vouchers or Packages is fully canceled and not rescheduled, the order will be canceled, and the User will receive a refund for the amount paid for their ticket or package.</p>
      <p>7.3.	Rescheduling: If an Event or Service/Experience for which the User purchased a Ticket/Product/Package/Experience is rescheduled, the Tickets/Vouchers and Packages will be validated for the new date. However, if the User notifies us within the specified timeframe, according to the situation, that they are not interested or unable to attend the rescheduled date, the order may be canceled, and a full refund of the sales price for their Tickets/Vouchers or Packages may be issued.</p>
      <p>7.3.1.	If no specific timeframe is mentioned for the situation, the default timeframe will be 48 hours before the rescheduled Event or Service date.</p>
      <p>7.4.	Failure by the User to notify us within the applicable timeframe that they will not attend the rescheduled Event or Service/Experience will be considered a reconfirmation of their order for Tickets/Vouchers or Packages for the Event, and the User will not be eligible for a refund.</p>
      <p>7.5.	To request a refund under Clause 7.3, the User must follow the instructions provided in the notification of the Event or Service change or submit a written request via the Parque Bondinho Pão de Açúcar® Customer Service Center (“SAC”) at (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br/requests/new" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br/requests/new</Link>) or via email at (<a href={"mailto:sac@bondinho.com.br"}>sac@bondinho.com.br</a>).</p>
      <p>7.6.	Refunds will be processed using the same payment method as the initial purchase.</p>

      <h6>8.	Limitation of Liability:</h6>

      <p>8.1.	To the fullest extent permitted by law, Parque Bondinho Pão de Açúcar and its Partners shall not be liable for any losses, injuries, or damages to any person (including the User) or property under the following terms:</p>
      <p>(a)	in any circumstances where there is no breach of contract or legal duty of care owed by us or the Event or Service/Experience Partner;</p>
      <p>(b)	in circumstances where such loss or damage is not a direct result of any breach; or</p>
      <p>(c) to the extent that any increase in loss or damage results from negligence, recklessness, lack of skill, or violation by the User or Visitor of the applicable terms and conditions and/or any relevant laws or regulations.</p>

      <h6>9.	Consumer Cancellation and Rescheduling Policy:</h6>

      <p>9.1.	Under Article 49 of the Consumer Defense Code, cancellation requests may be made within seven (7) calendar days for purchases made outside the Parque Bondinho Pão de Açúcar® premises. In this case, a full refund of the amount paid for the Ticket/Product/Package/Experience will be issued in the same payment method employed for the purchase.</p>
      <p>9.1.1	For purchases made on-site, whether through the physical ticket office or kiosks within the Parque Bondinho Pão de Açúcar® premises, if the User requests cancellation of the Ticket/Product/Package/Experience, a full refund will be guaranteed, provided the cancellation request is made no less than 24 hours before the Event/Boarding time indicated on the Ticket/Product/Package/Experience, as outlined in Clause 9.2.</p>
      <p>9.2.	If the Ticket/Product/Package/Experience is canceled 23h59min before the start time of the Event/Boarding as indicated on the Ticket/Product/Package/Experience, 20% of the amount paid for the Ticket/Product/Package/Experience will be withheld.</p>
      <p>9.3.	Cancellation requests made after the tour has started will not be eligible for refunds.</p>
      <p>9.4.	If rescheduling is necessary, visitors may request it more than 24 hours in advance of the scheduled tour start time, as long as there is availability for the desired new date and time. If the rescheduling request is made less than 24 hours before the tour time, it will be permitted only once, subject to the Park's availability. After the new time is set, no further rescheduling will be allowed.</p>
      <p>9.4.1. Tickets/Vouchers or Packages purchased through the ticket office or the website <Link to="/">www.parquebondinho.com.br</Link> may be rescheduled.</p>
      <p>9.4.2. Rescheduling requests made after the date and time of Boarding or the Event will not be accepted.</p>
      <p>9.4.3. Only the User who made the purchase can request a rescheduling.</p>
      <p>9.5.	All refunds outlined here apply to Tickets or Packages purchased directly at the physical ticket office or online through <Link to="/">www.parquebondinho.com.br</Link>.</p>
      <p>9.6.	For online purchases, cancellation requests must be submitted by the customer through the website <Link to="/">www.parquebondinho.com.br</Link> or via our customer service channels. On-site purchases must be canceled directly at the Ticket Office or through our customer service channels.</p>
      <p>9.6.1.	Only the User who made the purchase can request the cancellation of the order.</p>
      <p>9.7.	Upon receipt of the email confirming the cancellation of the purchase, the full amount paid will be refunded, subject to the provisions of Clauses 9.1 to 9.3, using the same payment method employed in the original transaction, adhering to the following timelines and conditions:</p>
      <p>9.7.1. If the purchase was made in cash, the User will be refunded immediately at the Ticket Office, if the requirements from Clauses 9.1 to 9.3 of this Cancellation Policy are met.</p>
      <p>9.7.2. For credit card purchases, Parque Bondinho Pão de Açúcar® will immediately notify the Credit Card Administrator of the refund, which will occur within five (5) business days.</p>
      <p>9.7.3. The credit to the cardholder will depend on the processing by the issuing bank and the statement closing date. The refund may appear in up to three billing cycles, which is beyond the control of Parque Bondinho Pão de Açúcar® and depends on the internal processes of each credit card administrator. We recommend contacting the credit card issuer directly.</p>
      <p>9.7.4. For purchases paid with a debit card at the Ticket Office, Parque Bondinho Pão de Açúcar® will immediately notify the Financial Institution of the refund, which will occur within five (5) business days. We recommend contacting the responsible Financial Institution directly.</p>
      <p>9.7.5. For payments made via PIX, the refund process will begin within 24 hours (1 day) from the confirmation of the cancellation and will be completed within a maximum of 72 (seventy-two) hours, provided the User submits the correct bank account information to process the refund. The amount will be returned to the same account used for the payment.</p>
      <p>9.7.6. Parque Bondinho Pão de Açúcar® is not responsible for delays in the process outlined in Clause 9.7.5 if the User fails to provide accurate bank account information for the refund.</p>
      <p>9.8.	For Package purchases, canceling/rescheduling one product will result in the cancellation/rescheduling of the other. For example, in the Guided Tour Package, which includes a Ticket and a Guided Tour, if one of the services is canceled/rescheduled, the other will also be canceled/rescheduled. Users wishing only to access the Park must purchase the corresponding Ticket separately.</p>
      <p>9.9.	If the User or others for whom Tickets, Packages, or Vouchers were purchased fail to appear at the designated time and place for the start of Boarding or the Event without prior notice, this will be considered a "No Show." In addition to Clause 9.3, no refund will be issued.</p>
      <p>9.10. In the event of abandonment or withdrawal from the Event after it has started, no refund or compensation will be provided for unused services, as the operators and guides will have already prepared all the contracted infrastructure.</p>
    </div>
  );
}

export default TermsOfPurchasePT;