import React from "react";

function ProgramOngsComponentPT() {
    return (
        <>
            <div className="programOng">
                <h1>Programa de Incentivo a Organizações Não Governamentais (ONGs)</h1>

                <h2>1. OBJETIVO</h2>
                <br /><p>Estabelecer diretrizes para o Programa de Incentivo a Organizações Não Governamentais (ONGs), visando garantir a transparência e equidade na concessão de desconto diferenciado para aquisição de bilhetes do Parque Bondinho Pão de Açúcar®, para uso exclusivo dos beneficiários de entidades sociais selecionadas.</p>

                <h2>2. ABRANGÊNCIA</h2>
                <br /><p>Aplica-se a todas as organizações não governamentais (ONGs) regularmente constituídas e legalizadas há pelo menos 02 (dois) anos, a contar da data de sua inscrição no Cadastro Nacional das Pessoas Jurídicas (CNPJ).</p>

                <h2>3. REFERÊNCIAS E COMPLEMENTARES</h2>
                <ul>
                    <li>PC.01-003 – Política Anticorrupção Iter</li>
                    <li>Código de Conduta e Ética de terceiros Iter</li>
                    <li>LGPD – Lei Geral de Proteção de Dados</li>
                    <li>Lei nº 12.813/2013 – Lei de Conflito de Interesses</li>
                    <li>Lei nº 12.846/2016 e Decreto nº 11.129/2022 – Lei Anticorrupção</li>
                    <li>PC- 389 Política Comercial para Canais de Bilheteria</li>
                </ul>

                <h2>4. SIGLAS E DEFINIÇÕES</h2>
                <ul>
                    <li><strong>ONGs:</strong> Organizações Não Governamentais</li>
                    <li><strong>Conflito de interesses:</strong> Configurado quando, por conta de interesse próprio ou alheio aos da empresa ou da Lei, um colaborador pode ser influenciado a agir contra os princípios ou interesses da empresa, tomando uma decisão inapropriada ou deixando de cumprir algumas de suas responsabilidades profissionais.</li>
                    <li><strong>Vantagem indevida:</strong> Refere-se a todas as concessões indevidas de qualquer coisa de valor e os pagamentos impróprios efetuados em um contexto de negócios, compreendendo a oferta, a promessa, o pagamento ou a entrega indevida de qualquer coisa de valor a um agente público ou a representante da administração pública, pessoa física ou pessoa jurídica, de maneira direta ou indireta.</li>
                </ul>

                <h2>5. DIRETRIZES</h2>

                <h3>5.1 Princípios Gerais</h3>
                <ul>
                    <li><strong>Integridade:</strong> Todas as ações devem estar em conformidade com as normas éticas e legais internas e externas pertinentes.</li>
                    <li><strong>Transparência:</strong> Todas as interações devem ser conduzidas com clareza e honestidade.</li>
                    <li><strong>Equidade:</strong> Todas as partes interessadas devem ser tratadas de forma justa.</li>
                    <li><strong>Colaboração:</strong> As ações devem promover a cooperação mútua.</li>
                </ul>

                <h3>5.2 Programa de Incentivo a Organizações Não Governamentais (ONGs)</h3>
                <br /><p>O Programa de Incentivo a Organizações Não Governamentais (ONGs) consiste em iniciativa de seleção de organizações não governamentais (ONGs), para concessão de desconto diferenciado na aquisição de bilhetes do Parque Bondinho Pão de Açúcar®, para uso exclusivo dos beneficiários atendidos pelas entidades selecionadas, em atividades sem fins lucrativos, visando apoiar ações de cunho social e contribuir com a geração de impacto positivo.</p>

                <br /><p>5.2.1 Condições de Participação</p>
                <ol>
                    <li>Poderão participar do presente Programa de Incentivo organizações que estejam regularmente constituídas e legalizadas há pelo menos 02 (dois) anos, a contar da data de sua inscrição no Cadastro Nacional das Pessoas Jurídicas (CNPJ).</li>
                    <li>Todos os comunicados sobre o processo seletivo, bem como o resultado da seleção será divulgado através do e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>O preenchimento das condições e dos pré-requisitos das organizações não governamentais (ONGs) será verificado durante o processo de seleção, mediante apresentação dos documentos obrigatórios indicados neste Programa.</li>
                    <li>O não envio ou atraso na apresentação dos documentos exigidos eliminará automaticamente a organização do processo seletivo.</li>
                    <li>Não serão aceitas nesse Programa, organizações que possuam, entre seus dirigentes estatutários, funcionários do Parque Bondinho Pão de Açúcar®, seus cônjuges ou parentes até o segundo grau.</li>
                    <li>A participação no processo de seleção também implica na responsabilização pessoal e intransferível das organizações e/ou seus representantes legais, no que se refere à veracidade das informações fornecidas ao Parque Bondinho Pão de Açúcar® e ao Grupo Iter, holding que administra o Parque Bondinho.</li>
                </ol>

                <br /><p>5.2.2 Forma de Inscrição</p>
                <ol>
                    <li>A inscrição no processo seletivo deverá ser feita, exclusivamente, através do e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, conjuntamente ao envio dos documentos obrigatórios. Todo o material orientativo e modelos serão disponibilizados pelo e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>Somente serão consideradas válidas as inscrições que atendam aos requisitos deste Programa.</li>
                    <li>A organização social autoriza que todos os dados e informações fornecidos sejam armazenados e utilizados pelo Parque Bondinho Pão de Açúcar®, para finalidade de inscrição no referido Programa, bem como o compartilhar com os órgãos competentes para o cumprimento de tal finalidade, de acordo com a Lei nº 13.709/2018 - Lei Geral de Proteção de Dados Pessoais (LGPD).</li>
                    <li>Durante todo o processo seletivo, eventuais dúvidas das organizações devem ser enviadas para o e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>A organização social deverá ser a única responsável pela autoria da inscrição e será responsável inclusive judicialmente, no caso de qualquer questionamento feito por terceiros, que diga respeito às informações prestadas ao Parque Bondinho Pão de Açúcar® e ao Grupo Iter.</li>
                </ol>

                <br /><p>5.2.3 Seleção</p>
                <ol>
                    <li>A seleção da organização não governamental (ONG) será realizada por comissão composta por colaboradores do Parque Bondinho Pão de Açúcar® e do Grupo Iter.</li>
                    <li>O processo de seleção das organizações não governamentais (ONGs), conduzido pela Comissão Técnica, será composto das seguintes etapas de avaliação, todas eliminatórias:
                        <ol type="a">
                            <li>Análise Documental.</li>
                            <li>Avaliação de Conformidade.</li>
                            <li>Análise das informações contidas no formulário de inscrição (*) e alinhamento com estratégia e valores do Parque Bondinho Pão de Açúcar®.</li>
                            <li>Seleção final da organização social.</li>
                        </ol>
                        (*) modelos serão disponibilizados através do e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.
                    </li>
                    <li>Critérios de análise:
                        <ul>
                            <li>Escopo de atuação dos projetos desenvolvidos pelas organizações sociais.</li>
                            <li>Objetivos do projeto e da visitação.</li>
                            <li>Experiência da organização.</li>
                        </ul>
                    </li>
                    <li>A seleção final da organização social estará condicionada ao quantitativo de bilhetes disponíveis no Programa de Incentivo, na ocasião do processo seletivo da instituição candidata. Anualmente, o Parque Bondinho destinará 5.000 ingressos para o Programa, os quais serão consumidos ao longo do ano, de acordo com a seleção de organizações não governamentais (ONGs).</li>
                    <li>Cada organização não governamental poderá consumir até 1.000 ingressos por ano.</li>
                    <li>O Parque Bondinho poderá alterar a quantidade disponibilizada mediante atualização deste documento.</li>
                    <li>O desconto a ser ofertado para as organizações não governamentais (ONGs) selecionadas será de 20% em qualquer bilhete.</li>
                    <li>Caberá única e exclusivamente à organização social selecionada, a responsabilidade pelos recursos necessários para aquisição dos bilhetes, e pela operacionalização das visitas, incluindo transporte dos beneficiários da entidade.</li>
                    <li>Caberá única e exclusivamente à organização social selecionada, a responsabilidade pelo cumprimento com as normas éticas e legais internas e externas pertinentes, durante toda a sua relação com o Parque Bondinho Pão de Açúcar®.</li>
                    <li>O resultado final do processo de seleção será informado, exclusivamente, através do e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, bem como o processo será considerado encerrado com a divulgação do referido resultado.</li>
                </ol>

                <br /><p>5.2.4 Documentos Necessários</p>
                <ol>
                    <li>As organizações não governamentais (ONGs) deverão apresentar, obrigatoriamente, no ato da inscrição os documentos abaixo:
                        <ul>
                            <li>Cartão CNPJ;</li>
                            <li>Estatuto ou Contrato Social atual, devidamente registrado no competente cartório;</li>
                            <li>Declaração de Responsabilidade do Proponente, assinado pelo representante legal; (*)</li>
                            <li>Formulário de Diligência Patrocínios e Parcerias assinado pelo representante legal da instituição; (*)</li>
                            <li>Formulário de Inscrição. (*)</li>
                        </ul>
                        (*) modelos serão disponibilizados através do e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.
                        (*) Reforçamos que todas as respostas poderão ser submetidas a diligências para comprovação e caso necessário, informações e documentos complementares aos documentos iniciais poderão ser solicitados, a qualquer tempo.
                        (*) Caso preencha a inscrição mais de uma vez, apenas a última resposta será considerada.
                    </li>
                    <li>Perderá o direito ao desconto diferenciado a qualquer tempo, sem necessidade de aviso prévio, a organização social que viole as premissas desta política e/ou que suspeita ou com envolvimento com situações de corrupção, fraude ou atos ilícitos, se reservando ao Parque Bondinho Pão de Açúcar® o direito de proceder com o levantamento das informações por todos os meios legais disponíveis.</li>
                </ol>

                <br /><p>5.2.5 Concessão de desconto diferenciado</p>
                <ol>
                    <li>O desconto diferenciado a ser concedido pelo Parque Bondinho Pão de Açúcar® está condicionado ao preenchimento de todos os requisitos deste Programa, e será informado à organização social selecionada através do e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, em até 15 (quinze) dias úteis após o recebimento de toda documentação obrigatória. Além disso, serão enviadas as orientações quanto aos trâmites e prazo para aquisição dos ingressos.</li>
                    <li>Para acesso dos beneficiários da organização social selecionada ao Parque Bondinho, será obrigatória, conjuntamente ao bilhete, a apresentação de documentação comprobatória de vínculo com a instituição (ex.: carta de autorização com o nome dos visitantes assinado pelo CNPJ e documento com foto). Para bilhetes promocionais (ex.: Bilhete Estado RJ e Bilhete Promocional para Brasileiros), também será obrigatória a apresentação de documento comprobatório no ato da utilização (ex.: identidade ou comprovante de residência), assim como para bilhetes meia entrada, conforme orientações contidas no site do Parque Bondinho (<a className="linkOng" href="http://sac.bondinho.com.br">sac.bondinho.com.br</a>).</li>
                    <li>É expressamente proibida a utilização dos bilhetes para fins de revenda, redistribuição comercial, ou qualquer atividade que implique em exploração econômica dos mesmos.</li>
                </ol>

                <br /><p>5.2.6 Cláusula Anticorrupção</p>
                <ol>
                    <li>Os proponentes, bem como por seus respectivos sócios, administradores, executivos, empregados, prepostos, subcontratados e procuradores, ao se inscreverem no processo de seleção deste Programa concordam que:
                        <ol type="i">
                            <li>Conhecem as normas de prevenção à corrupção previstas na legislação brasileira, dentre elas o Código Penal Brasileiro, a Lei de Improbidade Administrativa – Lei nº 8.429/1992 e a Lei nº 12.846/2013 e seus regulamentos (em conjunto, “Leis Anticorrupção”) e se comprometem a cumpri-la fielmente, por si e/ou por seus sócios, administradores e colaboradores, bem como exigir o seu cumprimento pelos terceiros por ela contratados. Declaram, ainda, que suas atividades estão em conformidade com as Leis Anticorrupção e que nos últimos 05 (cinco) anos não sofreram nenhuma investigação, inquérito ou processo administrativo ou judicial relacionados ao descumprimento das Leis Anticorrupção ou de lavagem de dinheiro e que suas atividades estão em conformidade com tais dispositivos legais ora mencionados.</li>
                            <li>Não poderão oferecer qualquer tipo de pagamento ou qualquer bem de valor pessoal, nem de forma direta, nem de forma indireta, para qualquer agente público ou indivíduo empregado, vinculado ou associado com qualquer entidade que possua participação societária ou controle direto ou indireto pelo Poder Público, em qualquer de suas esferas.</li>
                            <li>Estão recebendo como apoio financeiro incentivado, valores condizentes com o valor de mercado dos produtos e/ou serviços, e não estão recebendo qualquer valor ou recursos para realização de subornos ou quaisquer outros atos que violem o exposto nesta cláusula.</li>
                            <li>Que defenderão, indenizarão e não responsabilizarão o Parque Bondinho Pão de Açúcar®, seus dirigentes, diretores e funcionários contra qualquer ação, despesas ou responsabilizações que poderão surgir em conexão com esta cláusula.</li>
                        </ol>
                    </li>
                </ol>

                <br /><p>5.2.7 Condições Gerais</p>
                <ol>
                    <li>As organizações não governamentais (ONGs) selecionadas assumem total responsabilidade por quaisquer eventuais reivindicações de terceiros que se sintam prejudicados pela sua participação no presente processo de seleção.</li>
                    <li>Serão desclassificados e excluídos do processo de seleção as organizações não governamentais (ONGs) que:
                        <ul>
                            <li>Não se encaixarem nos quesitos obrigatórios deste Programa;</li>
                            <li>Apresentarem projetos ou materiais que venham a ser considerados pela Comissão Técnica como ofensivos;</li>
                            <li>Cometerem qualquer tipo de fraude;</li>
                            <li>Não apresentarem os documentos obrigatórios;</li>
                            <li>Não cumprirem as premissas do Programa.</li>
                        </ul>
                    </li>
                    <li>Quaisquer dúvidas, divergência ou situações não previstas neste Programa serão apreciadas e decididas pelo Parque Bondinho Pão de Açúcar®, cuja decisão é irrecorrível.</li>
                    <li>O Parque Bondinho se reserva ao direito, de a seu exclusivo critério, alterar os termos deste Programa, publicando versão atualizada no site <a className="linkOng" href="http://www.bondinho.com.br">www.bondinho.com.br</a>, sempre se pautando pela legalidade e transparência.</li>
                    <li>Todo e qualquer ato, contrato ou compromisso firmado pela organização social, para fins de participação neste processo de seleção, são de sua única e exclusiva responsabilidade.</li>
                    <li>Todo e qualquer material de comunicação em que a marca do Parque Bondinho Pão de Açúcar® for inserida ou citada, deverá ser submetido à aprovação prévia da empresa.</li>
                    <li>As dúvidas deverão ser encaminhadas exclusivamente para o e-mail <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                </ol>

                <h2>6. CONTROLE DE VERSÕES</h2>
                <br /><p>Este padrão passa a vigorar a partir da data de sua publicação, sendo revogadas todas as disposições em contrário e as versões anteriores.</p>
            </div>
        </>
    )
}

export default ProgramOngsComponentPT;