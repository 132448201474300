import React, { useEffect, useState } from 'react';

import api from "../../services/api";

import { Row, Col } from 'react-bootstrap';

import { Container } from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion'

import { useTranslation } from "react-i18next";

import Form from "react-bootstrap/Form";

import './DownloadCenter.scss';

//import object from './locales/downloads.json';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageDafault from '../../templates/PageDafault';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import ModalApiError from '../../components/Modal/ModalApiError/ModalApiError';
import monitoringAPI from '../../services/monitoring';

function DownloadCenter() {
    const [t] = useTranslation();
    const [list, setList] = useState<any>(null);
    //const [file, setFile] = useState<any>(null);
    const [select, setSelect] = useState<any>(0);
    const [apiErrorMessage, setApiErrorMessage] = useState<string>('');

    useEffect(() => {
        async function getList() {
            try {
                const res: any = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/RI/GetListFileAsync`);

                if (res.status === 200) {
                    if (res.data.statusCode === 200) {
                        setList(res.data.data.nivel.nivel);
                        monitoringAPI(200, 'RI', 'GetListFileAsync', res?.data?.data?.texto || 'Sucesso', '', '/api/RI/GetListFileAsync', '');
                    } else {
                        setApiErrorMessage(res.data?.errorMessage || t('modalApiError.sorry'));
                        monitoringAPI(400, 'RI', 'GetListFileAsync', res.data?.errorMessage || res.data.data.texto || t('modalApiError.sorry'), '', '/api/RI/GetListFileAsync', '');
                    }
                } else {
                    setApiErrorMessage(res.data?.errorMessage || t('modalApiError.sorry'));
                    monitoringAPI(400, 'RI', 'GetListFileAsync', res.data?.errorMessage || res.data.data.texto || t('modalApiError.sorry'), '', '/api/RI/GetListFileAsync', '');
                }
            } catch (error: any) {
                setApiErrorMessage(error?.response?.data?.errorMessage || t('modalApiError.errorAPI'));
                monitoringAPI(400, 'RI', 'GetListFileAsync', error?.response?.data?.errorMessage || t('modalApiError.errorAPI'), '', '/api/RI/GetListFileAsync', '');
            }
        }
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getVoucher(idItem: any, itemName: any) {
        const getVou = async () => {
            try {
                const res = await api.get(
                    `${process.env.REACT_APP_SERVER_URL_API}/api/RI/GetFileAsync/${idItem}`
                );
                if (res.data.statusCode !== 400) {
                    if (res.data.statusCode !== 204) {
                        monitoringAPI(200, 'RI', 'GetFileAsync', res?.data?.data?.texto || 'Sucesso', '', `/api/RI/GetFileAsync/${idItem}`, '');
                        const link = document.createElement('a');
                        link.href = `data:text/plain;base64, ${res.data.data}`;
                        link.setAttribute(
                            'download',
                            `${itemName}`,
                        );
                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // // Clean up and remove the link
                        link?.parentNode?.removeChild(link);
                    } else {
                        monitoringAPI(204, 'RI', 'GetFileAsync', 'Erro 204', '', `/api/RI/GetFileAsync/${idItem}`, '');
                    }
                } else{
                    monitoringAPI(400, 'RI', 'GetFileAsync', res?.data?.data?.texto || 'Retornado status diferente de 200', '', `/api/RI/GetFileAsync/${idItem}`, '');
                    
                }
            } catch (error: any) {
                monitoringAPI(400, 'RI', 'GetFileAsync', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', `/api/RI/GetFileAsync/${idItem}`, '');
            }
        }
        getVou();
    };

    function HandleSelect(e: any) {
        setSelect(e.target.value);
    };

    const convertDate = (date: string) => {
        let [data, hora] = date.split(' ');
        let [mes, dia, ano] = data.split('/');
        let newDate = `${dia}/${mes}/${ano}`;
        let newTimeDate = `${newDate} ${hora}`;
        return newTimeDate;
    };

    return (
        <>
            {list !== null
                ?
                <>
                    <Helmet>
                        <html lang={i18next.language} />
                        <title>Documentos Financeiros| Parque Bondinho</title>
                        <meta name="description" content="Saiba mais sobre os demonstrativos financeiros do Parque Bondinho Pão de Açúcar. Acesse nosso site e confira!" />
                        <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                        {/* Essential META Tags */}
                        <meta property="og:title" content="Documentos Financeiros| Parque Bondinho" />
                        <meta property="og:type" content="TouristAttraction" />
                        <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                        <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                        {/* Non-Essential, But Recommended */}
                        <meta property="og:description" content="Saiba mais sobre os demonstrativos financeiros do Parque Bondinho Pão de Açúcar. Acesse nosso site e confira!" />
                        <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                        <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                        <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
                    </Helmet>

                    <PageDafault>
                        <Container className="download-center py-5">
                            <h1 className="mb-5 text-center display-2">{t("downloadCenter.title")}</h1>
                            <div className='bg-white pb-4'>
                                <div className='d-flex justify-content-center py-4'>
                                    <Form.Select
                                        onChange={HandleSelect}
                                    >
                                        {
                                            list.map((item: any, index: any) => {
                                                return (
                                                    <>
                                                        <option value={index}>{item.name}</option>
                                                    </>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                                {
                                    list[select].nivel.map((trimester: any, index: any) => {
                                        return (
                                            <>
                                                <Accordion className="p-3" defaultActiveKey="1">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>{trimester.name}</Accordion.Header>
                                                        <Accordion.Body className="px-0 py-0">
                                                            {
                                                                trimester.files.map((accordion: any, index: any) => {
                                                                    return (
                                                                        <>
                                                                            <Row className="py-3 mx-0">
                                                                                <Col sm={10} className="col-10 px-4 my-auto">
                                                                                    <Row>
                                                                                        <Col sm={4} md={3} lg={2} className="my-auto">
                                                                                            <span><b>{convertDate(accordion.date)}</b></span>
                                                                                        </Col>
                                                                                        <Col sm={8} md={7} lg={8} className="my-auto">
                                                                                            <a
                                                                                                href={`#${accordion.name.split(".")[0]}`}
                                                                                                onClick={() => { getVoucher(accordion.id, accordion.name) }}
                                                                                            >
                                                                                                {accordion.description}
                                                                                            </a>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col sm={2} className="col-2 d-flex justify-content-end align-items-center px-3 px-md-4">
                                                                                    <Row>
                                                                                        <Col sm={12} md={12} lg={12} className="d-flex justify-content-end px-3">
                                                                                            <a
                                                                                                href={`#${accordion.name.split(".")[0]}`}
                                                                                                onClick={() => { getVoucher(accordion.id, accordion.name) }}
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={["fal", "file-pdf"]}
                                                                                                    size="2x"
                                                                                                    style={{ color: "#FF6600", width: "20px" }}
                                                                                                />
                                                                                            </a>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </>
                                        )
                                    })
                                }
                            </div>


                        </Container>
                    </PageDafault>
                </>
                :
                <>
                    <Helmet>
                        <html lang={i18next.language} />
                        <title>Relações com Investidores | Parque Bondinho</title>
                        <meta name="description" content="Saiba mais sobre os demonstrativos financeiros do Parque Bondinho Pão de Açúcar. Acesse nosso site e confira!" />
                        <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                        {/* Essential META Tags */}
                        <meta property="og:title" content="Relações com Investidores | Parque Bondinho" />
                        <meta property="og:type" content="TouristAttraction" />
                        <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                        <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                        {/* Non-Essential, But Recommended */}
                        <meta property="og:description" content="Saiba mais sobre os demonstrativos financeiros do Parque Bondinho Pão de Açúcar. Acesse nosso site e confira!" />
                        <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                        <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                        <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
                    </Helmet>

                    <PageDafault>
                        <Container className="download-center py-5">
                            <h1 className="mb-5 text-center display-2">{t("downloadCenter.title")}</h1>
                            <div className="bg-white">
                                <div className='d-flex justify-content-center pt-4 pb-5'>
                                    <div className="animated-background w-25" style={{ height: "42px" }}></div>
                                </div>
                                <div className="p-3">
                                    <div className="animated-background mx-auto" style={{ width: "100%", height: "52px", borderRadius: "10px" }}></div>
                                </div>
                                <div className="p-3">
                                    <div className="animated-background mx-auto" style={{ width: "100%", height: "52px", borderRadius: "10px" }}></div>
                                </div>
                            </div>
                        </Container>
                    </PageDafault>
                </>
            }
            <ModalApiError apiErrorMessage={apiErrorMessage} setApiErrorMessage={setApiErrorMessage} />
        </>

    );
}

export default DownloadCenter;