import React from 'react';

import './TermsOfPurchase.scss';
import { Link } from 'react-router-dom';

function TermsOfPurchaseES() {
  return (
    <div className='bg-terms-of-purchase'>
      <h5>Introducción:</h5>

      <ul>
        <li>
          El(los) término(s) “Evento(s)” se refiere a las actividades de ocio promovidas por el Parque Bondinho Pão de Açúcar, como: acceso al Parque y al Bondinho, conciertos de música, eventos deportivos, festivales, exposiciones u otros contenidos culturales y de entretenimiento que requieran un pase de acceso.
        </li>
        <li>
          El(los) término(s) “Boleto(s)” se refiere al derecho de acceso al evento(s) o servicio(s), al Parque o al Bondinho. Este acceso puede otorgarse a través de un formato físico, digital u otros métodos que incluyan una forma de validación como códigos de barras, biometría o códigos QR emitidos exclusivamente por el Parque Bondinho Pão de Açúcar, comercializados o distribuidos a través de nuestra plataforma digital o taquillas físicas.
        </li>
        <li>
          El(los) término(s) “Usuario(s)” se refiere a cualquier persona que: (i) se registre en la plataforma del Parque Bondinho Pão de Açúcar, tras conocer y aceptar los Términos de Uso, para utilizar cualquiera de las herramientas disponibles; (ii) adquiera un Boleto/Producto/Paquete/Experiencia, productos o servicios a través de cualquier canal de ventas disponible, incluyendo la Taquilla Oficial (ventas físicas); o (iii) visitantes en general.
        </li>
        <li>
          El(los) término(s) “Paquete(s)” se refiere a los paquetes ofrecidos para la venta que incluyen un Boleto y uno o más productos o servicios adicionales, como una Visita Guiada, entre otros.
        </li>
        <li>
          Los términos “Producto(s),” “Servicio(s),” y “Experiencia(s)” se refieren a los productos/servicios/experiencias ofrecidos para la venta en las plataformas físicas o en línea del Parque Bondinho Pão de Açúcar.
        </li>
        <li>
          El(los) término(s) “Voucher(s)” se refiere a los comprobantes de compra de productos, servicios o experiencias.
        </li>
        <li>
          Esta “Política de Compra” establece los términos y condiciones aplicables a la compra de Boletos.
        </li>
      </ul>

      <h6>1.	Contrato de Vínculo Legal:</h6>

      <p>1.	En esta Política de Compra, nos referimos a cualquier producto o servicio ofrecido para la venta por nosotros.</p>

      <p>1.1.	Para realizar la compra de un Boleto/Producto/Paquete/Experiencia, ya sea a través de la Taquilla o de nuestra plataforma en línea, el Usuario debe tener al menos 18 (dieciocho) años y ser capaz de celebrar contratos jurídicamente vinculantes. Si el Usuario realiza una compra en línea, también debe tener una cuenta en el sitio <Link to='/'>www.bondinho.com.br</Link>, quedando igualmente sujeto a sus Términos de Uso.</p>
      <p>1.2.	Cualquier compra realizada en nuestra plataforma (física o en línea) constituye un contrato jurídicamente vinculante sujeto a: (i) esta Política de Compra; (ii) los términos y condiciones especiales aplicables a un artículo y/o evento específico; (iii) otros términos y condiciones del/los Socio(s) de los posibles eventos que se realicen en el Parque Bondinho Pão de Açúcar. El Usuario debe leer esta Política de Compra detenidamente antes de realizar una compra.</p>
      <p>1.3.	Al adquirir uno o más artículos en nuestra plataforma (física o en línea), el Usuario reconoce y acepta estar sujeto a los términos y condiciones de esta Política de Compra. En caso de no estar de acuerdo con esta Política de Compra, con otros términos y condiciones aplicables, o si no puede cumplirlos, el Usuario no debe realizar la compra.</p>
      <p>1.4.	Nos reservamos el derecho de realizar cambios periódicos en esta Política de Compra. Siempre que hagamos tales modificaciones, publicaremos la versión actualizada en nuestro sitio web. Por lo tanto, recomendamos al Usuario que revise esta Política de Compra regularmente para mantenerse informado sobre sus términos y condiciones vigentes. Todas las compras están sujetas a la versión aplicable de esta Política de Compra publicada al momento de la compra. Si el Usuario no está de acuerdo con cualquier versión revisada de esta Política de Compra o no puede cumplirla, no debe realizar la compra.</p>
      <p>1.5.	El sitio de venta de Boleto/Producto/Paquete/Experiencia pertenece a Parque Bondinho Pão de Açúcar Tecnologia Eletrônica Ltda., que otorgó la licencia de uso a Companhia Caminho Aéreo Pão de Açúcar..</p>
      <p>1.6.	La plataforma de ventas de Boleto/Producto/Paquete/Experiencia es propiedad de Parque Bondinho Pão de Açúcar Tecnologia Eletrônica Ltda., prestadora de servicios de Companhia Caminho Aéreo Pão de Açúcar.</p>
      <p>1.7.	El Parque Bondinho Pão de Açúcar presta servicios de venta y promoción de eventos, pero no forma parte de la producción u organización de los paseos promocionados en el sitio.</p>
      <p>1.8.	Las reglas de compra y cancelación son establecidas por Companhia Caminho Aéreo Pão de Açúcar.</p>

      <h6>2. Precios, Pagos y Pedidos:</h6>

      <p>2.1.	Todos los precios de los Boletos, Paquetes, Productos y Experiencias comercializados a través de nuestros canales oficiales incluyen los impuestos aplicables según la legislación vigente.</p>
      <p>2.2.	Los métodos de pago aceptados incluyen tarjetas de débito y efectivo (solo para compras presenciales), tarjetas de crédito (Visa / Elo / Mastercard / Diners / Amex / Hipercard / Discover / JCB) y transferencias mediante PIX.</p>
      <p>2.3.	Todas las compras están sujetas a la verificación de tarjetas de crédito o débito (si corresponde) u otras verificaciones de seguridad, por lo que se podrán solicitar documentos de confirmación de registro para finalizar tu pedido. El pedido puede ser cancelado si no pasa nuestro proceso de verificación o si no se recibe el pago completo. En casos excepcionales, si el banco o proveedor de pago cancela el pago asociado, nos reservamos el derecho de cancelar y reembolsar cualquier pedido para el cual ya se haya enviado una confirmación.</p>
      <p>2.4.	Nos reservamos el derecho de cancelar cualquier pedido que sospechemos razonablemente que se haya realizado de manera fraudulenta, sin previo aviso al Usuario, y todos los artículos obtenidos como parte de dichos pedidos serán anulados. Asegúrate de leer todos los detalles de la descripción del artículo y revisar tu selección antes de realizar la compra.</p>
      <p>2.5. La compra estará sujeta a la disponibilidad del Boleto/Producto/Paquete/Experiencia y a la aprobación de la operadora de tu tarjeta de crédito o la compensación del pago por boleto bancario, lo cual puede tardar hasta 5 (cinco) días. El cliente solo recibirá la confirmación de la programación del paseo una vez que se haya confirmado el pago.</p>

      <h6>3.	Entrega:</h6>

      <p>3.1.	Si la compra se realiza a través de nuestro sitio web (www.bondinho.com.br) y la opción de entrega en línea del Boleto/Producto/Paquete/Experiencia está disponible, el Usuario es responsable de: (i) asegurarse de iniciar sesión en su cuenta y descargar sus Boletos/Vouchers a tiempo antes del embarque/evento; o (ii) proporcionar una dirección de correo electrónico válida para la entrega en línea de Boletos/Vouchers y asegurarse de poder recibirlos por correo electrónico (por ejemplo, asegurándose de que su bandeja de entrada no rechace, devuelva o bloquee cualquier correo electrónico). Además, debe verificar regularmente su bandeja de entrada, incluyendo las carpetas de correo no deseado y SPAM.</p>
      <p>3.2.	El Usuario no tendrá derecho a ningún reembolso si el Boleto/Voucher se pierde, no se redime, no se recibe en su bandeja de entrada o si no realiza la descarga de los Boletos/Vouchers desde su cuenta en nuestro sitio web (www.bondinho.com.br) debido a su falla en proporcionar información de entrega correcta y completa y/o en garantizar su posesión del Boleto/Voucher.</p>
      <p>3.3.	El Usuario debe contactarnos a través de nuestro Servicio de Atención al Cliente (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br</Link>) de inmediato si hay algún error o problema con su Boleto/Voucher, o si no recibe sus Boletos/Vouchers según lo solicitado y/o como se describe en la confirmación del pedido.</p>

      <h6>4.	Derechos y Obligaciones del Usuario:</h6>

      <p>4.1.	Cualquier compra de un Boleto/Producto/Paquete/Experiencia realizada a través de nuestros canales oficiales (sitio web <Link to='/'>www.bondinho.com.br</Link> o taquilla física) concede una licencia personal de acceso al evento/lugar, la cual puede ser cancelada según los términos de este documento.</p>
      <p>4.2.	Al recibir o retirar tus Boletos/Vouchers, guárdalos en un lugar seguro. No seremos responsables por Boletos/Vouchers perdidos, robados, extraviados, dañados o destruidos después de haber sido entregados al Usuario. La exposición a la luz solar o al calor puede dañar los boletos/vouchers físicos. Además, recomendamos que el Usuario no publique fotos o detalles de sus Boletos/Vouchers en línea, ya que esto podría permitir que terceros falsifiquen o comprometan su integridad. No seremos responsables si se niega tu entrada como resultado de esta práctica.</p>
      <p>4.3.	Revisa cuidadosamente los datos de tu pedido (programación, fecha, horario, cantidades) antes de confirmar tu compra. No se permitirá el cambio de tu boleto/voucher a otra fecha programada, salvo en los casos previstos en la Política de Cancelación (Cláusula 9). Tu Boleto/Producto/Paquete/Experiencia es un producto único, es decir, después de su compra, ya no estará disponible para la venta.</p>
      <p>4.4.	Para compras realizadas con una tarjeta de crédito de un tercero, es indispensable presentar una copia del documento oficial de identificación con foto del titular de la tarjeta utilizada y una copia únicamente del anverso de la tarjeta. La falta de presentación de estos documentos podrá resultar en la denegación de la entrada.</p>
      <p>4.5. En el caso de compras de Boletos/Productos/Paquetes/Experiencias realizadas por internet, puedes presentar tu Boleto/Voucher en formato físico (imprimiéndolo) o en formato digital (en tu teléfono móvil o tableta, por ejemplo). Estos documentos contendrán un 'Código QR' que será escaneado en los torniquetes.</p>
      <p>4.6.	Los Boletos y/o Vouchers obtenidos de fuentes no autorizadas pueden ser inválidos, perdidos, robados o falsificados. El Parque Bondinho Pão de Açúcar, al verificar tal práctica, podrá revocarlos, anularlos o cancelarlos sin derecho a reembolso.</p>
      <p>4.7.	El Parque Bondinho Pão de Açúcar atenderá y/o brindará soporte únicamente al Usuario registrado, es decir, a la persona física o jurídica debidamente registrada, y exclusivamente en adquisiciones que puedan demostrarse que fueron realizadas a través de la cuenta de dicho Usuario en el sitio de venta de Boletos/Productos/Paquetes/Experiencias, o por el comprador del boleto/voucher físico en el caso de compras realizadas en la taquilla.</p>
      <p>4.8.	La violación de cualquiera de los Términos y Condiciones del Parque o de las Reglas de Acceso, así como cualquier comportamiento inaceptable que pueda causar daños, molestias o lesiones, otorgará al Parque Bondinho Pão de Açúcar el derecho de expulsar al Usuario del lugar.</p>

      <h6>5.	Restricciones de Boletos/Vouchers:</h6>

      <p>5.1.	Los Boletos/Vouchers pueden ser vendidos sujetos a ciertas restricciones de entrada o uso, como requisitos de edad mínima. Cualquier restricción será mostrada o notificada al Usuario antes o durante el momento de la reserva. Es responsabilidad del Usuario verificar dicha información importante presentada durante el proceso de compra. El Parque Bondinho Pão de Açúcar no será responsable si el Usuario o cualquier invitado en su reserva se le niega la admisión debido a una falla para cumplir o comprobar que cumple con las restricciones aplicables (por ejemplo, un requisito de edad mínima).</p>
      <p>5.2.	Se deberán observar las Reglas de Descuentos dispuestas en el sitio web <Link to='/'>www.bondinho.com.br</Link> al momento de la compra por parte del Usuario.</p>
      <p>5.3. Media Entrada y Entrada Gratuita: Las personas que adquieran boletos de tipo media entrada deberán dirigirse a la Taquilla para la verificación de los documentos originales/impresos que validen su derecho al beneficio. Posteriormente, recibirán pulseras de identificación.</p>

      <h6>6.	Horarios y Entradas de Evento(s) o Acceso al Parque:</h6>

      <p>6.1.	Ten en cuenta que los horarios de inicio de los Evento(s) o servicios anunciados, como Visita Guiada, Desayuno, entre otros, están sujetos a cambios.</p>
      <p>6.2.	Al participar en un Evento, el Usuario acepta ser fotografiado, filmado y/o grabado en relación con el Evento y/o por motivos de protección y seguridad, incluyendo grabaciones realizadas por la policía. Además, acepta que las fotografías, videos y grabaciones de audio y/o audiovisuales resultantes puedan ser utilizadas en cualquier medio, para cualquier propósito, en cualquier momento y lugar del mundo por el Parque Bondinho Pão de Açúcar y sus socios.</p>

      <h6>7.	Cancelación y Modificaciones de Eventos/Servicios por parte del Parque Bondinho Pão de Açúcar®:</h6>

      <p>7.1.	Si un Evento o Servicio es cancelado, reprogramado o sustancialmente modificado, haremos todos los esfuerzos razonables para comunicar dicha información. Sin embargo, no podemos garantizar que el Usuario será notificado de tales cambios, ya que estos pueden ocurrir en cualquier momento, incluso por decisión de una autoridad pública, caso fortuito y/o fuerza mayor.</p>
      <p>7.2.	Si un Evento para el cual el Usuario compró Boletos/Vouchers o Paquetes es cancelado completamente y no se reprograma, el pedido será cancelado, y el Usuario será reembolsado por el monto pagado por su boleto o paquete.</p>
      <p>7.3.	Reprogramación: Si un Evento o Servicio/Experiencia para el cual el Usuario compró un Boleto/Producto/Paquete/Experiencia es reprogramado, los Boletos/Vouchers y Paquetes serán válidos para la nueva fecha. Sin embargo, si el Usuario nos notifica dentro del plazo especificado según la situación que no está interesado o no puede asistir a la nueva fecha programada, podrá cancelar su pedido y recibir el reembolso total del precio pagado por sus Boletos/Vouchers o Paquetes.</p>
      <p>7.3.1.	Si no se especifica un plazo en la situación concreta, el plazo estándar será de 48 horas antes de la nueva fecha del Evento o Servicio reprogramado.</p>
      <p>7.4.	Si el Usuario no nos notifica dentro del plazo aplicable que no asistirá al Evento o Servicio/Experiencia reprogramado, se considerará una reconfirmación de su pedido, y no podrá solicitar un reembolso.</p>
      <p>7.5.	Para solicitar el reembolso según la Cláusula 7.3., el Usuario deberá seguir las instrucciones proporcionadas en la notificación de cambio recibida o solicitarlo por escrito a través del "SAC" (Servicio de Atención al Cliente) del Parque Bondinho Pão de Açúcar® (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br/requests/new" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br/requests/new</Link>) o mediante el correo electrónico (<a href={"mailto:sac@bondinho.com.br"}>sac@bondinho.com.br</a>).</p>
      <p>7.6.	Los reembolsos se realizarán a través del mismo método de pago utilizado en la compra inicial.</p>

      <h6>8.	Limitación de responsabilidad:</h6>

      <p>8.1.	En la máxima medida permitida por la ley, el Parque Bondinho Pão de Açúcar y sus Socios no serán responsables por ninguna pérdida, lesión o daño a cualquier persona (incluido el Usuario) o propiedad, en los siguientes términos:</p>
      <p>(a)	En cualquier circunstancia donde no haya violación de contrato o deber legal de cuidado por nuestra parte o por parte del Socio del Evento o Servicio/Experiencia;</p>
      <p>(b)	En circunstancias donde dicha pérdida o daño no sea resultado directo de alguna violación; o</p>
      <p>(c) En la medida en que cualquier aumento en la pérdida o daño sea resultado de la negligencia, imprudencia, impericia o violación, por parte del Usuario o Visitante, de los términos y condiciones aplicables y/o de cualquier ley o reglamento vigente.</p>

      <h6>9.	Política de Cancelación y Reprogramación por parte del Consumidor:</h6>

      <p>9.1.	Conforme al artículo 49 del Código de Defensa del Consumidor, el pedido de cancelación podrá realizarse en los próximos 7 (siete) días corridos si la compra se realizó fuera del establecimiento del Parque Bondinho Pão de Açúcar®. En este caso, se garantiza el reembolso total del valor pagado por el Boleto/Producto/Paquete/Experiencia, en la misma forma en que se efectuó el pago.</p>
      <p>9.1.1.	Para compras realizadas dentro del establecimiento (taquilla física o tótems del  Parque Bondinho Pão de Açúcar®), si el Usuario solicita la cancelación del Boleto/Producto/Paquete/Experiencia, se garantiza el reembolso completo siempre que el pedido de cancelación se realice con al menos 24 horas de anticipación respecto al Evento/Embarque indicado en el Boleto/Producto/Paquete/Experiencia, según lo estipulado en la Cláusula 9.2.</p>
      <p>9.2.	En caso de cancelación de Boleto/Producto/Paquete/Experiencia, realizada con anticipación de 23h59min antes del inicio del Evento/Embarque indicado en el Boleto/Producto/Paquete/Experiencia, se aplicará una retención del 20% del valor pagado.</p>
      <p>9.3.	No se aceptarán cancelaciones solicitadas después del inicio del paseo.</p>
      <p>9.4.	Si es necesario reprogramar, el visitante puede solicitarlo con más de 24 horas de antelación al horario de inicio del paseo, siempre que haya disponibilidad para la nueva fecha y hora deseadas. Si la solicitud de reprogramación se realiza con menos de 24 horas de antelación al horario del paseo, se permitirá la reprogramación solo una vez, según la disponibilidad del Parque, y no será posible realizar nuevas reprogramaciones, independientemente del nuevo horario establecido.</p>
      <p>9.4.1. Podrán reprogramarse los Boletos/Vouchers o Paquetes adquiridos a través de la taquilla o del sitio web <Link to='/'>www.parquebondinho.com.</Link></p>
      <p>9.4.2. No se permitirá la reprogramación si la solicitud se realiza después de la fecha y hora del Evento/Embarque.</p>
      <p>9.4.3. Solo el Usuario titular de la compra puede solicitar la reprogramación.</p>
      <p>9.5.	Los reembolsos indicados aplican a Boletos o Paquetes comprados directamente en la taquilla física o en línea a través del sitio web <Link to='/'>www.parquebondinho.com.</Link>.</p>
      <p>9.6.	Para compras realizadas en canales en línea, las cancelaciones deben ser solicitadas por el cliente a través del sitio web <Link to='/'>www.parquebondinho.com.</Link> o nuestros canales de atención. Las compras realizadas en las instalaciones del Parque deben cancelarse directamente en la taquilla o mediante nuestros canales de atención.</p>
      <p>9.6.1.	Solo el Usuario titular de la compra puede solicitar la cancelación.</p>
      <p>9.7.	Una vez recibida la confirmación de cancelación, se reembolsará el monto pagado según lo estipulado en las Cláusulas 9.1 a 9.3., utilizando el mismo método de pago en los siguientes plazos y condiciones:</p>
      <p>9.7.1. Para compras en efectivo, el reembolso será inmediato en la taquilla, siempre que se cumplan las disposiciones de las Cláusulas 9.1 a 9.3.</p>
      <p>9.7.2. Para compras con tarjeta de crédito, el Parque Bondinho Pão de Açúcar® comunicará el reembolso a la entidad emisora de la tarjeta de crédito, en un máximo de 5 días hábiles.</p>
      <p>9.7.3. El crédito dependerá del procesamiento del banco emisor y la fecha de cierre del estado de cuenta, pudiendo visualizarse en hasta tres ciclos de facturación, plazo que es independiente del Parque Bondinho Pão de Açúcar®, ya que funciona de acuerdo a los procedimientos internos de cada administradora de tarjeta de crédito. Recomendamos que sea realizada una consulta directa con el banco emisor.</p>
      <p>9.7.4. Para compras con tarjeta de débito en la taquilla, el Parque Bondinho Pão de Açúcar® comunicará el reembolso a la entidad financiera en un máximo de 5 días hábiles. En este caso, recomendamos que sea realizada una consulta directa con la Institución Financiera responsable.</p>
      <p>9.7.5. Para pagos vía PIX, el proceso de reembolso se iniciará en un máximo de 24 horas (1 día) tras la confirmación de la cancelación, completándose en un plazo máximo de 72 horas, contados a partir de la fecha que el Usuario envíe los datos bancarios para el reembolso. El valor debe ser devuelto en la misma cuenta usada para el pago del pedido.</p>
      <p>9.7.6. El Parque Bondinho Pão de Açúcar® no se responsabiliza por retrasos causados en el procedimiento previsto por la Cláusula 9.7.5, en caso de información incorrecta proporcionada por el Usuario para recibir el reembolso.</p>
      <p>9.8.	En el caso de la compra de un Paquete, la cancelación/reprogramación de un producto implicará la cancelación/reprogramación del otro. Por ejemplo, en el caso del Paquete de Visita Guiada, que incluye la Entrada y la Visita Guiada, si se cancela/reprograma uno de los servicios, el otro también será cancelado/reprogramado. De este modo, el Usuario que desee únicamente el servicio de acceso al Parque deberá adquirir la Entrada correspondiente.</p>
      <p>9.9.	En caso de que el Usuario y las demás personas para quienes haya adquirido Entradas, Paquetes o Vales no se presenten en la hora y el lugar programados para el inicio del Embarque o Evento, sin previa comunicación, resultará en un “No Show”, lo que, en adición a lo dispuesto en la Cláusula 9.3, no se reembolsarán los valores pagados.</p>
      <p>9.10. En el caso de abandono o desistimiento del Evento después de iniciado, tampoco habrá derecho a reembolso o compensación por los servicios no utilizados, ya que los operadores y guías ya habrán preparado toda la infraestructura contratada.</p>
    </div>
  );
}

export default TermsOfPurchaseES;