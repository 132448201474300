function ExperienceRulesES() {
    return (
        <ul className="mt-4">
            <li><p>La experiencia comienza a las 4:15 AM y su horario puede cambiar debido a variaciones en el amanecer. El horario estará disponible en el boleto y, en caso de cambios, el servicio de atención al cliente se pondrá en contacto.</p></li>
            <li><p>No se reembolsarán solicitudes de cancelación realizadas (i) después de la fecha y hora del embarque o evento; o (ii) después de la realización del paseo.</p></li>
            <li><p>Las solicitudes de cancelación de compras realizadas en el sitio web deben enviarse al servicio de atención al cliente por correo electrónico a <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a>.</p></li>
            <li><p>Es posible reprogramar la experiencia hasta 7 días después de la fecha original.</p></li>
            <li><p>En caso de abandono o desistimiento de la experiencia después de haber comenzado, no habrá derecho a reembolso ni compensación por los servicios no utilizados, ya que los operadores y guías ya habrán preparado toda la infraestructura contratada.</p></li>
            <li><p>Si el usuario y otras personas que compraron la experiencia Amanecer en el Parque Bondinho no se presentan en la hora y lugar marcados sin comunicación previa, se considerará un "No-Show", lo que significa que no se reembolsarán los valores pagados.</p></li>
            <li><p>El acceso al restaurante Clássico Sunset Club y al Bosque das Artes, ambos ubicados en el Morro del Pan de Azúcar, no ofrece accesibilidad completa, siendo necesario el uso de escaleras.</p></li>
            <li><p>El desayuno incluido en la experiencia Amanecer en el Parque Bondinho termina a las 8:00 AM, pero los visitantes pueden permanecer en el restaurante Clássico Sunset Club para consumir el menú tradicional (no incluido en el producto) y también en el Parque Bondinho para visitarlo libremente.</p></li>
            <li><p>No hay restricción de edad para esta experiencia.</p></li>
            <li><p>Los niños menores de 3 años pueden participar en la experiencia de forma gratuita.</p></li>
            <li><p>En caso de restricciones alimentarias, es necesario informar con al menos 3 días de antelación.</p></li>
        </ul>
    )
}

export default ExperienceRulesES;