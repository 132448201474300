
import { useTranslation } from "react-i18next";
import './ProgramOngs.scss';
import PageDafault from '../../templates/PageDafault';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import ProgramOngsComponentEN from './ProgramOngsComponent/components/ProgramOngsComponentEN/ProgramOngsComponentEN';
import ProgramOngsComponentPT from './ProgramOngsComponent/components/ProgramOngsComponentPT/ProgramOngsComponentPT';
import ProgramOngsComponentES from './ProgramOngsComponent/components/ProgramOngsComponentES/ProgramOngsComponentES';

function ProgramOngs() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <html lang={i18next.language} />
        <title>Programa de Incentivo a ONGs | Parque Bondinho</title>
        <meta name="description" content="Conheça Programa de Incentivo a ONGs" />
        <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

        {/* Essential META Tags */}
        <meta property="og:title" content="Conheça Programa de Incentivo a ONGs | Parque Bondinho" />
        <meta property="og:type" content="TouristAttraction" />
        <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
        <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

        {/* Non-Essential, But Recommended */}
        <meta property="og:description" content="Conheça Conheça Programa de Incentivo a ONGs!" />
        <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

        <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
        <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
      </Helmet>

      <PageDafault>
        <div className="container py-5">
          <h1 className="mb-5 text-center display-2">{i18next.language === "pt" ? 'Conheça Programa de Incentivo a ONGs' : 
                                                      i18next.language === "en" ? 'NGO Incentive Program' : 
                                                      i18next.language === "es" ? 'Programa de Incentivos para ONGs' 
                                                      : 'Conheça Programa de Incentivo a ONGs'}</h1>
          <div className="card py-4 px-3">
            {i18next.language === "pt" ? <ProgramOngsComponentPT /> : i18next.language === "en" ? <ProgramOngsComponentEN /> : i18next.language === "es" ? <ProgramOngsComponentES /> : <ProgramOngsComponentPT />}

          </div>
        </div>
      </PageDafault>
    </>
  );
}

export default ProgramOngs;