function ExperienceRulesPT() {
    return (
        <ul className="mt-4">
            <li><p>A experiência começa a partir das 4h15 e pode ter o horário alterado devido às variações no nascer do sol. O horário estará disponível no ingresso e, em caso de alteração, o atendimento entrará em contato.</p></li>
            <li><p>Não serão reembolsados pedidos de cancelamentos efetuados (i) após a data e horário do Embarque ou Evento; ou (ii) após a realização do passeio.</p></li>
            <li><p>Os pedidos de cancelamento para compras no site, deverão ser feitos ao SAC no contato pelo e-mail <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br.</a></p></li>
            <li><p>Há possibilidade de reagendamento da experiência até 7 dias após a data original.</p></li>
            <li><p>Na hipótese de abandono ou desistência da experiência, após iniciado o seu curso, igualmente não haverá qualquer direito de reembolso ou compensação pelos serviços não utilizados, pois os operadores e guias já terão preparado toda a infraestrutura contratada.</p></li>
            <li><p>No caso de o usuário e demais pessoas que tenham adquirido o Amanhecer no Parque Bondinho não comparecerem na hora e local marcados para o início da experiência, sem comunicação prévia, acarretará “No-Show”, ou seja, não serão reembolsados os valores pagos.</p></li>
            <li><p>O acesso ao restaurante Clássico Sunset Club e ao Bosque das Artes, ambos localizados no Morro do Pão de Açúcar, não oferecem acessibilidade completa, sendo necessário o uso de escadas.</p></li>
            <li><p>O Café da Manhã disponibilizado no Amanhecer no Parque Bondinho termina às 8h, mas os visitantes podem permanecer no Restaurante Clássico Sunset Club para consumo do menu tradicional (não incluso no produto) e também no Parque Bondinho para visitação livre.</p></li>
            <li><p>Não há restrição de idade para este passeio.</p></li>
            <li><p>Crianças menores de 3 anos não pagam pela experiencia.</p></li>
            <li><p>Em caso de restrição alimentar, é necessário informar com no mínimo 3 dias de antecedência.</p></li>

        </ul>
    )
}

export default ExperienceRulesPT;