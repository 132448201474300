import React from 'react';

import './TermsOfPurchase.scss';
import { Link } from 'react-router-dom';

function TermsOfPurchasePT() {
  return (
    <div className='bg-terms-of-purchase'>
      <h5>Introdução:</h5>

      <ul>
        <li>
          O(s) termo(s) “Evento(s)” referem-se as atividades de lazer promovidas pelo Parque Bondinho Pão de Açúcar, sejam estas: acesso ao Parque e Bondinho, shows de música, eventos de esporte, festivais, exposições, ou qualquer outro conteúdo cultural e de entretenimento, cuja entrada requeira um passe de acesso.
        </li>
        <li>
          O(s) termo(s) “Ingresso(s)” referem-se ao direito de acesso ao(s) evento(s) ou serviço(s), ao Parque ou ao Bondinho. Este acesso por se dar por meio de um papel, de forma digital, ou outros métodos que contenham uma forma de validação com código de barras, biometria ou QR Codes emitidos de forma exclusiva pelo Parque Bondinho Pão de Açúcar e, comercializados ou distribuídos por meio de nossa plataforma digital ou bilheterias físicas.
        </li>
        <li>
          O(s) termo(s) “Usuário(s)”, referem-se a qualquer pessoa (i) que efetue cadastro na plataforma do Parque Bondinho Pão de Açúcar, após conhecer e concordar com os Termos de Uso, para a utilização de qualquer uma das ferramentas disponíveis; (ii) aqueles adquirentes de Ingresso/Produto/Pacote/Experiência, produtos ou serviços por meio de qualquer outro canal de vendas disponível, inclusive a Bilheteria Oficial (vendas físicas); ou (iii) visitantes em geral.
        </li>
        <li>
          O(s) termo(s) “Pacote(s)”, referem-se aos pacotes oferecidos para venda de um Ingresso e um ou mais produtos ou serviços adicionais, como Visita Guiada, entre outros.
        </li>
        <li>
          Os termos “Produto(s)”, “Serviço(s)” e “Experiência(s)”, referem-se aos produtos/serviços/experiências oferecidos para venda nas plataformas físicas ou on-line do Parque Bondinho Pão de Açúcar.
        </li>
        <li>
          O(s) termo(s) “Voucher(s)”, referem-se aos comprovantes de compras de produtos, serviços ou experiências.
        </li>
        <li>
          Esta “Política de Compra” estabelece os termos e condições aplicáveis às compras de Ingressos.
        </li>
      </ul>

      <h6>1.	Contrato de vinculação legal:</h6>

      <p>1.	Nesta Política de Compra, nos referimos a quaisquer produtos e serviços oferecidos para venda realizada por nós.</p>

      <p>1.1.	Para fazer uma compra de Ingresso/Produto/Pacote/Experiência, seja através de Bilheteria ou de nossa plataforma on-line, o Usuário precisa ter pelo menos 18 (dezoito) anos e ser capaz de celebrar contratos juridicamente vinculativos. Se o Usuário estiver realizando uma compra on-line, também precisa ter uma conta no site <Link to={"/"}>www.bondinho.com.br</Link>, de modo que estará igualmente submetido aos Termos de Uso deste.</p>
      <p>1.2.	Qualquer compra em nossa plataforma (física ou on-line) constitui um contrato juridicamente vinculativo que está sujeito a: (i) esta Política de Compra; (ii) quaisquer termos e condições especiais declarados aplicáveis a um Item e/ou Evento específico; (iii) outros termos e condições do(s) Parceiro(s) de eventuais Evento(s) que ocorram no Parque Bondinho Pão de Açúcar. O Usuário deve ler esta Política de Compra cuidadosamente antes de realizar uma compra.</p>
      <p>1.3.	Ao comprar um ou mais Itens em nossa plataforma (física ou on-line), o Usuário reconhece e concorda em ficar vinculado aos termos e condições desta Política de Compra. No caso de discordância com esta Política de Compra ou quaisquer outros termos e condições aplicáveis, ou se não puder cumprir com algum deles, o Usuário não deverá realizar uma compra.</p>
      <p>1.4.	Reservamo-nos o direito de, periodicamente, fazer alterações nesta Política de Compra. Sempre que fizermos tais alterações, publicaremos a versão atualizada desta Política de Compra em nosso site. Portanto, recomendamos que o Usuário verifique esta Política de Compra regularmente para se manter informado sobre seus termos e condições atuais. Todas as compras estão sujeitas à versão aplicável desta Política de Compra que foi publicada no momento da compra. Se o Usuário não concordar com qualquer versão revisada desta Política de Compra, ou se não puder cumpri-la, não deverá realizar uma compra.</p>
      <p>1.5.	O site de venda de Ingresso/Produto/Pacote/Experiência pertence ao Parque Bondinho Pão de Açucar Tecnologia Eletrônica LTDA. que concedeu licença de uso à Companhia Caminho Aéreo Pão De Açúcar.</p>
      <p>1.6.	A plataforma de vendas dos Ingresso/Produto/Pacote/Experiência é de propriedade do Parque Bondinho Pão de Açúcar Tecnologia Eletrônica Ltda., prestadora de serviços da Companhia Caminho Aéreo Pão De Açúcar.</p>
      <p>1.7.	O Parque Bondinho Pão de Açucar presta serviços de venda e divulgação dos eventos, não fazendo parte da produção ou organização dos passeios divulgados no site.</p>
      <p>1.8.	As regras de compra e cancelamento são estabelecidas pela Companhia Caminho Aéreo Pão de Açúcar.</p>

      <h6>2.	Preços, Pagamentos e Pedidos:</h6>

      <p>2.1.	Todos os preços dos Ingressos, Pacotes, Produtos e Experiências comercializados por meio dos nossos canais oficiais incluem tributos incidentes na operação, conforme legislação vigente.</p>
      <p>2.2.	Os métodos de pagamentos aceitos incluem cartões de débito e dinheiro (apenas para compras presenciais), cartões de crédito (Visa / Elo / Mastercard / Diners / Amex / Hipercard / Discover / JCB) e transferências via PIX.</p>
      <p>2.3.	Todas as compras estão sujeitas à verificação do cartão de crédito ou débito (se aplicável), ou outras verificações de segurança, de modo que poderão ser solicitados documentos de comprovação de cadastro para finalização de seu pedido. O pedido pode ser cancelado se não for aprovado em nosso processo de verificação ou se o pagamento não for recebido integralmente. Em raras circunstâncias, se o pagamento for cancelado pelo banco ou provedor de pagamento associado, nos reservamos o direito de cancelar e reembolsar qualquer pedido para o qual uma confirmação tenha sido enviada.</p>
      <p>2.4.	Reservamo-nos o direito de cancelar quaisquer pedidos que razoavelmente suspeitemos terem sido feitos de forma fraudulenta, sem qualquer aviso ao Usuário, e todos e quaisquer Itens obtidos como parte de tais pedidos serão anulados. Certifique-se de ler os detalhes completos da descrição do item e conferir a sua seleção antes da compra.</p>
      <p>2.5. A compra estará sujeita à disponibilidade de Ingresso/Produto/Pacote/Experiência e à aprovação da operadora de seu cartão de crédito ou compensação do boleto bancário, que poderá levar até 5 (cinco) dias. O cliente somente receberá a confirmação do agendamento do passeio após a confirmação do pagamento.</p>

      <h6>3.	Entrega:</h6>

      <p>3.1.	Se a compra for efetivada através de nosso site (<Link to={"/"}>www.bondinho.com.br</Link>) e a opção de entrega online do Ingresso/Produto/Pacote/Experiência estiver disponível, o Usuário é responsável por (i) se assegurar de fazer o login em sua conta e baixar seus Ingressos/Vouchers em tempo hábil antes do embarque/evento; ou (ii) fornecer um endereço de e-mail válido para entrega on-line de Ingressos/Vouchers e se assegurar de poder receber a entrega destes por e-mail (por exemplo, se assegurando que sua caixa de e-mail não rejeite, devolva ou impeça qualquer e-mail de ser entregue), de modo que também deve verificar sua caixa de e-mail de forma regular – incluindo lixo eletrônico e SPAM.</p>
      <p>3.2.	O Usuário não terá direito a qualquer reembolso quando o Ingresso/Voucher for perdido, não resgatado, não recebido em sua caixa de e-mail ou por sua falha em realizar o download de Ingressos/Vouchers através de sua conta em nosso site (<Link to={"/"}>www.bondinho.com.br</Link>), por resultado de sua falha em fornecer informações de entregas corretas e completas e/ou falha em garantir a sua posse com relação ao Ingresso/Voucher.</p>
      <p>3.3.	O Usuário deve entrar em contato conosco através de nosso SAC (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br</Link>) imediatamente se houver qualquer engano ou erro com seu Ingresso/Voucher, ou se não receber seus Ingresso/Voucher conforme solicitado e/ou conforme descrito na confirmação do pedido.</p>

      <h6>4.	Direitos e Obrigações do Usuário:</h6>

      <p>4.1.	Qualquer compra de Ingresso/Produto/Pacote/Experiência por meio de nossos canais oficiais (Site <Link to={"/"}>www.bondinho.com.br</Link> ou Bilheteria física) concede uma licença pessoal de acesso ao evento/local, esse passível de cancelamento nos termos deste instrumento.</p>
      <p>4.2.	Ao receber ou retirar seus Ingressos/Vouchers, guarde-os em local seguro. Não seremos responsáveis por quaisquer Ingresso/Voucher perdidos, roubados, extraviados, danificados ou destruídos após o recebimento pelo Usuário. A exposição à luz solar ou calor podem danificar os ingressos/vouchers físicos. Além disso, recomendamos que o Usuário não publique fotos ou detalhes de seus Ingressos/vouchers online, pois isso pode permitir que terceiros falsifiquem seus ingressos/vouchers ou comprometam a integridade destes e não seremos responsáveis se sua entrada for recusada como resultado.</p>
      <p>4.3.	Confira atentamente os dados de seu pedido (agendamento, dia, período, quantidades) antes da confirmação de sua compra. Não será permitida a troca do seu ingresso/voucher para outra data agendada, salvo nas hipóteses previstas na Política de Cancelamento (Cláusula 9). O seu Ingresso/Produto/Pacote/Experiência é um produto único, ou seja, após sua compra ele não estará mais disponível para venda.</p>
      <p>4.4.	Para compras com cartão de crédito de terceiros, é indispensável a apresentação de uma cópia do documento oficial de identificação com foto do titular do cartão de crédito utilizado na compra e uma cópia somente da frente do cartão. A não apresentação dos documentos fica sob pena de não ser autorizada a entrada.</p>
      <p>4.5.	Nas hipóteses de compra de Ingresso/Produto/Pacote/Experiência via internet, você pode apresentar seu Ingresso/Voucher em formato de papel (imprimindo o mesmo) ou em formato digital (pelo telefone celular ou tablet, por exemplo). Estes documentos conterão um 'QR Code' que será identificado nas catracas.</p>
      <p>4.6.	Ingressos e/ou Vouchers obtidos de fontes não autorizadas podem ser inválidos, perdidos, roubados ou falsificados; o Parque Bondinho Pão de Açucar, em verificando tal prática poderá revogá-los, anulá-los ou cancelá-los, sem direito a qualquer reembolso.</p>
      <p>4.7.	O Parque Bondinho Pão de Açúcar atenderá e/ou prestará suporte apenas ao Usuário, ou seja, a pessoa física ou jurídica devidamente cadastrada, e exclusivamente em aquisições que comprovadamente tenham ocorrido através da conta daquele Usuário junto ao site de venda de Ingresso/Produto/Pacote/Experiência, ou, pelo próprio comprador do ingresso/voucher físico, no caso da compra realizada na bilheteria.</p>
      <p>4.8.	A violação de qualquer um dos Termos e Condições do Parque ou Regras de Acesso, além de qualquer comportamento inaceitável que possa causar danos, incômodos ou ferimentos, dará ao Parque Bondinho Pão de Açucar o direito de expulsar o Usuário do local.</p>

      <h6>5.	Restrições de Ingressos/Vouchers:</h6>

      <p>5.1.	Os Ingressos/Vouchers podem ser vendidos sujeitos a certas restrições de entrada ou uso, como idade mínima para entrada. Quaisquer restrições serão exibidas ou notificadas ao Usuário antes ou no momento da sua reserva. É uma responsabilidade do Usuário verificar tais informações importantes exibidas durante o processo de compra. O Parque Bondinho Pão de Açúcar não será responsável se o Usuário ou qualquer convidado em sua reserva for recusado a admissão devido a uma falha em atender ou comprovar que este(s) atende(m) a quaisquer restrições (por exemplo, um requisito de idade mínima).</p>
      <p>5.2.	Deverão ser observadas as Regras de Descontos dispostas no site <Link to={"/"}>www.bondinho.com.br</Link> no ato da compra pelo Usuário.</p>
      <p>5.3.	Meia-Entrada e Gratuidade: as pessoas que adquirirem ingressos do tipo meia entrada deverão se dirigir à Bilheteria para verificação dos documentos originais/impressos, que validem o direito ao benefício e receberão pulseiras de identificação.</p>

      <h6>6.	Horários e Entradas de Evento(s)/ ou Acesso ao Parque:</h6>

      <p>6.1.	Observe que os horários de início do(s) Evento(s) ou serviços anunciados – tais como: Visita Guiada, Café da Manhã, entre outros – estão sujeitos a alterações.</p>
      <p>6.2.	Ao participar de um Evento, o Usuário concorda em ser fotografado, filmado e/ou gravado em relação ao Evento e/ou para proteção e segurança, incluindo filmagem pela polícia. Ademais, concorda que as fotografias, vídeos e gravações de áudio e/ou gravações audiovisuais resultantes podem ser usadas em toda e qualquer mídia para qualquer finalidade a qualquer momento em todo o mundo pelo Parque Bondinho Pão de Açucar e seus parceiros.</p>

      <h6>7.	Cancelamento e Alterações de Eventos/Serviços pelo Parque Bondinho Pão de Açúcar®:</h6>

      <p>7.1.	Se um Evento ou Serviço for cancelado, reagendado ou substancialmente alterado, envidaremos todos os esforços razoáveis para divulgar a informação. No entanto, não podemos garantir que o Usuário será informado de tal cancelamento, reagendamento ou alteração, haja vista que tal fato pode acontecer a qualquer momento, inclusive por decisão de autoridade pública, caso fortuito e/ou força maior.</p>
      <p>7.2.	Caso um Evento para o qual o Usuário comprou Ingressos/Vouchers ou Pacotes for integralmente cancelado e não reagendado, o pedido será cancelado e o Usuário será reembolsado com relação ao valor pago pelo seu ingresso ou pacote.</p>
      <p>7.3.	Reagendamento: se um Evento ou Serviço/Experiência para o qual o Usuário comprou Ingresso/Produto/Pacote/Experiência, for reagendado, os Ingressos/Vouchers e Pacotes serão validados para a nova data. No entanto, se o Usuário nos notificar dentro do prazo especificado de acordo com a situação, de que não possui interesse ou não poderá comparecer na nova data agendada, poderá o seu pedido ser cancelado e receber o reembolso integral do preço de venda de seus Ingressos/Vouchers ou Pacotes.</p>
      <p>7.3.1.	Caso não seja especificado prazo na situação concreta, o prazo padrão será de 48 horas antes da data do Evento ou Serviço reagendado.</p>
      <p>7.4.	A falha do Usuário em não nos notificar dentro do prazo aplicável de que o usuário não irá comparecer no Evento ou Serviço/Experiência remarcado, será considerada uma reconfirmação de seu pedido de Ingressos/Vouchers ou Pacotes para o Evento, e o usuário não poderá solicitar o reembolso.</p>
      <p>7.5.	Para que seja solicitado o reembolso de acordo com a Cláusula 7.3., o Usuário deverá seguir as instruções fornecidas na notificação de alteração do Evento ou Serviço recebida ou solicitar por escrito através do “SAC” do Parque Bondinho Pão de Açucar® (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br/requests/new" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br/requests/new</Link>) ou solicitar por escrito através do e-mail do “SAC” do Parque Bondinho Pão de Açucar® (<a href={ "mailto:sac@bondinho.com.br"}>sac@bondinho.com.br</a>).</p>
      <p>7.6.	Os reembolsos serão efetuados através do mesmo meio de pagamento utilizado na compra inicial.</p>
      
      <h6>8.	Limitação de responsabilidade:</h6>

      <p>8.1.	Na extensão máxima permitida por lei, o Parque Bondinho Pão de Açúcar e seus Parceiros não serão responsáveis por quaisquer perdas, ferimentos ou danos a qualquer pessoa (incluindo o Usuário) ou propriedade, nos seguintes termos:</p>
      <p>(a)	em quaisquer circunstâncias em que não haja violação de contrato ou dever legal de cuidado devido por nós ou pelo Parceiro de Evento ou Serviço/Experiência;</p>
      <p>(b)	em circunstâncias em que tal perda ou dano não seja diretamente resultado de qualquer violação; ou</p>
      <p>(c) na medida em que qualquer aumento em qualquer perda ou dano resulte da negligência, imprudência, imperícia ou violação, do Usuário ou Visitante, dos termos e condições aplicáveis e/ou a quaisquer leis ou regulamentos aplicáveis.</p>
      
      <h6>9.	Política de Cancelamento e Reagendamento pelo Consumidor:</h6>
      
      <p>9.1.	Conforme disposição do artigo 49 do Código de Defesa do Consumidor, o pedido de cancelamento poderá ser realizado em até 07 (sete) dias corridos, no caso de aquisição fora do estabelecimento do Parque Bondinho Pão de Açúcar®. Nesta hipótese, é garantida a restituição integral do valor pago pelo Ingresso/Produto/Pacote/Experiência, na forma em que o pagamento foi efetivado.</p>
      <p>9.1.1.	No caso de aquisição dentro do estabelecimento, seja através da bilheteria física ou totens no estabelecimento do Parque Bondinho Pão de Açúcar®, caso o Usuário requeira o cancelamento do Ingresso/Produto/Pacote/Experiência, é garantida a restituição integral do valor pago pelo Ingresso/Produto/Pacote/Experiência, desde que, o pedido de cancelamento não seja efetuado em prazo inferior a 24 (vinte e quatro) horas com relação ao Evento/Embarque constante do Ingresso/Produto/Pacote/Experiência, nos termos da Cláusula 9.2.</p>
      <p>9.2.	Na hipótese de cancelamento do Ingresso/Produto/Pacote/Experiência efetuado com antecedência de 23h59min até o horário do início do Evento/Embarque constante do Ingresso/Produto/Pacote/Experiência, aplicar-se-á a retenção de 20% (vinte por cento) do valor pago pelo Ingresso/Produto/Pacote/Experiência.</p>
      <p>9.3.	Não serão reembolsados pedidos de cancelamentos efetuados após o horário do início do passeio.</p>
      <p>9.4.	Se for necessário o reagendamento, o visitante pode solicitá-lo com mais de 24h de antecedência do horário de início do passeio, desde que haja disponibilidade para a nova data e hora desejados. Caso o reagendamento seja solicitado com antecedência de menos de 24h até o horário do passeio, será permitido o reagendamento uma única vez, conforme disponibilidade do Parque, e não será mais possível reagendar independente do novo horário marcado.</p>
      <p>9.4.1. Poderão ser remarcados Ingressos/Voucher ou Pacotes adquiridos através da bilheteria ou do site <Link to={"/"}>www.parquebondinho.com</Link>.</p>
      <p>9.4.2. Não será realizado o reagendamento caso o pedido seja efetuado após a data e horário do Embarque ou Evento.</p>
      <p>9.4.3. Somente o Usuário, titular da compra, pode efetuar a solicitação de reagendamento do pedido.</p>
      <p>9.5.	Todas as restituições aqui previstas são aplicáveis aos Ingressos ou Pacotes comprados diretamente na bilheteria física ou on-line através do site <Link to={"/"}>www.parquebondinho.com</Link>.</p>
      <p>9.6.	Para as compras realizadas nos canais online, os pedidos de cancelamentos deverão ser realizados pelo cliente através do próprio site <Link to={"/"}>www.parquebondinho.com</Link> ou pelos nossos canais de atendimento. Para as compras realizadas nas instalações do Parque, as mesmas devem ser canceladas diretamente na Bilheteria ou através dos nossos canais de atendimento.</p>
      <p>9.6.1.	Somente o Usuário, titular da compra, pode efetuar a solicitação de cancelamento do pedido.</p>
      <p>9.7.	Recebido o e-mail de confirmação de cancelamento da compra, os valores integrais pagos na aquisição serão estornados, observadas as disposições das Cláusulas 9.1 a 9.3, através do mesmo método de pagamento utilizado para conclusão da transação seguindo os seguintes prazos e condições:</p>
      <p>9.7.1. Caso a compra tenha sido efetuada em dinheiro, o Usuário será restituído imediatamente no ato do pedido de cancelamento perante a Bilheteria, desde que atendidas às disposições das Cláusulas 9.1 a 9.3 deste Termo de Cancelamento.</p>
      <p>9.7.2. No caso de a compra ter sido efetuada em cartão de crédito, o Parque Bondinho Pão de Açúcar® comunicará o estorno a ser realizado de forma imediata, em até 05 (cinco) dias úteis, a Administradora do Cartão de Crédito.</p>
      <p>9.7.3. O crédito ao portador do cartão dependerá do processamento deste valor pelo banco emissor e da data de fechamento da fatura. O estorno poderá ser visualizado em até três faturas, prazo este que independe do Parque Bondinho Pão de Açúcar®, já que decorre dos procedimentos internos de cada administradora de cartão de crédito. Recomendamos que seja realizada consulta direta com o banco emissor do cartão de crédito.</p>
      <p>9.7.4. Se a compra for paga através de um cartão de débito na Bilheteria, o Parque Bondinho Pão de Açúcar® comunicará o estorno a ser realizado de forma imediata, em até 05 (cinco) dias úteis, a Instituição Financeira. Neste caso, recomendamos que seja realizada consulta direta com a Instituição Financeira responsável.</p>
      <p>9.7.5. Para pedidos pagos via PIX, o procedimento de devolução será iniciado em até 24h (1 dia), contados a partir da confirmação do cancelamento, com prazo máximo de conclusão de 72 (setenta e duas) horas, contados da data em que o Usuário fornecer os dados relativos a conta bancária para a efetivação do reembolso. O valor deverá ser devolvido na mesma conta utilizada para pagamento do pedido.</p>
      <p>9.7.6. O Parque Bondinho Pão de Açúcar® não se responsabiliza por eventual atraso no procedimento previsto pela Cláusula 9.7.5, caso o Usuário deixe de fornecer os dados corretos da conta bancária indicada para receber o reembolso.</p>
      <p>9.8.	No caso de compra de Pacote, o cancelamento/remarcação de um produto enseja o cancelamento/remarcação do outro. Exemplo do Pacote Visita Guiada, que prevê o Ingresso e a Visita Guiada, na hipótese de cancelamento/remarcação de um dos serviços, o outro será igualmente cancelado/remarcado. Deste modo, o Usuário que deseja apenas o serviço de acesso ao Parque, deverá adquirir o correspondente Ingresso.</p>
      <p>9.9.	No caso de o Usuário e demais pessoas para quem tenha adquirido Ingresso, Pacote ou Voucher, não comparecerem na hora e local marcados para o início do Embarque ou Evento sem comunicação prévia, acarretará o “No Show”, de modo que, em adução a Cláusula 9.3, não serão reembolsados os valores pagos.</p>
      <p>9.10. Na hipótese de abandono ou desistência do Evento após iniciado o seu curso, igualmente não haverá qualquer direito de reembolso ou compensação pelos serviços não utilizados, pois os operadores e guias já terão preparado toda a infraestrutura contratada.</p>
    </div>
  );
}

export default TermsOfPurchasePT;