import React, { useState, useEffect, MouseEvent, useRef } from 'react';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
//import Collapse from "react-bootstrap/Collapse";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import Logo from '../../../assets/img/PARQUE_BONDINHO_110ANOS_CMYK1.png';

import BRFlag from '../../../assets/icons/brazil.svg';
import ESFlag from '../../../assets/icons/spain.svg';
import ENFlag from '../../../assets/icons/united-kingdom.svg';
//import iconUser from "../../../assets/icons/fa-user.svg";
//import iconBarsLarge from '../../../assets/icons/fa-bars-large.svg';
//import menu from "../../../assets/icons/menu.svg";

import CartHeader from '../../Cart/CartHeader';

import './NavHeader.scss';
import AuthLogin from '../../Authenticated/AuthLogin/AuthLogin';
import MenuSideBar from './MenuSidebar/MenuSideBar';

const NavHeader = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [activeFlag] = useState<any>({ pt: BRFlag, en: ENFlag, es: ESFlag });
  const [lng, setLng] = useState(i18next.language);
  const [isNewHours, setIsNewHours] = useState<boolean>(false);

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  let todayDate = yyyy + '-' + mm + '-' + dd;
  let exceptionalDate = '2024-11-27';

  const changeLanguage = (e: MouseEvent<HTMLButtonElement>) => {
    var element = (e.target as HTMLButtonElement).value;
    setLng(element);
    i18next.changeLanguage(element);
  };

  const auth = localStorage.getItem('bpasiteAuth') || '{}';

  function logOut() {
    localStorage.removeItem('bpasiteAuth');
    window.location.reload();
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          var elem: any = document.getElementById('authLoginDropdown');
          if (elem) {
            elem.classList.remove('active');
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }


  function keepDropdownOpen() {
    var elem: any = document.getElementById('authLoginDropdown');

    if (elem && !elem.classList.contains('active')) {
      elem.classList.add('active');
    }
  }

  const OpeningHours = () => {
    return (
      <div style={{ lineHeight: "1.75" }}>
        <small className="text-white content-hours pb-1">
          {' '}
          <span style={{ marginBottom: ".2rem" }}>{t('navHeader.hours.working')}</span>
        </small>
        <small className="text-white content-hours">
          <span>
            {t('navHeader.hours.workingWeek')}
          </span>
        </small>
        <small className="text-white content-hours">
          <span>
            {t('navHeader.hours.lastWeek')}
          </span>
        </small>
      </div>
    );
  };

  const OpeningHoursWeekend = () => {
    return (
      <>
        <small className="text-white content-hours">
          <span>
            {t('navHeader.hours.workingWeekend')}
          </span>
        </small>
        <small className="text-white content-hours">
          <span>
            {' '}
            {t('navHeader.hours.lastWeekend')}
          </span>
        </small>
        
      </>
    );
  };

  const OpeningHoursNew = () => {
    return (
      <div>
        <small className="text-white content-hours pb-1" style={{ marginBottom: ".2rem" }}>
          {t('navHeader.hours.working')}
        </small>        
        <small className="text-white content-hours" style={{ lineHeight: "1.5" }}>
          {t('navHeader.hoursNew.workingWeek')}

          {/* {<ExceptionalText />} */}
        </small>

        <small className="text-white content-hours" style={{ lineHeight: "1.5" }}>
          {t('navHeader.hoursNew.lastWeek')}

          {/* {<ExceptionalText />} */}
        </small>
      </div>
    );
  };

  /* const OpeningHoursWeekendNew = () => {
    return (
      <div>
        <small className="text-white content-hours">
          <span>
            {t('navHeader.hoursNew.workingWeekend')}{' '}
            {t('navHeader.hoursNew.lastWeekend')}
          </span>
        </small>
      </div>
    );
  }; */

  // const ExceptionalText = () => {
  //   return (
  //     <div className='pt-1'>
  //       {<small className="text-white content-hours" style={{ fontSize: "clamp(.6rem, 1vw,.7rem)", fontWeight: "700", lineHeight: "1.5", whiteSpace: 'nowrap' }}>
  //         {t('navHeader.hoursNew.exceptionalText1')}
  //       </small>}

  //       {<small className="text-white content-hours" style={{ fontSize: "clamp(.6rem, 1vw,.7rem)", fontWeight: "700", lineHeight: "1.5", whiteSpace: 'nowrap' }}>
  //         {t('navHeader.hoursNew.exceptionalText2')}
  //       </small>}

  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   let date = new Date().toLocaleString("pt-BR");
  //   let dateNow = date.split(" ")[0].split("/").reverse().join("").replaceAll(",", "");

  //   if (Number(`${dateNow}`) >= Number("20241220") && Number(`${dateNow}`) <= Number("20250131")) {
  //     setIsNewHours(true);
  //   } else {
  //     setIsNewHours(false);
  //   }
  // }, [])

  return (
    <>
      <nav className={`navbar navbar-expand navbar-light bg-primary ${todayDate === exceptionalDate ? 'exceptional' : ''}`} >
        <div className="container flex-md-row" style={{ maxHeight: '90px', flexWrap: 'nowrap' }}>
          <div className='d-flex w-75'>
            {/* Botão Menu */}
            <button
              className="navbar-toggler d-block "
              type="button"
              onClick={() => {
                setOpen(!open);
              }}
              aria-controls="menuSideBar"
              aria-expanded={open}
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon
                icon={['fas', 'bars']}
                size="2x"
                style={{ color: '#fff', width: '30px', marginRight: '20px' }}
              />
            </button>
            {/* Botão Menu */}

            <MenuSideBar open={open} setOpen={setOpen} />

            {/* Logo Site */}
            <a href="/" className="navbar-brand" style={{ marginRight: '0' }}>
              <img
                alt="Logotipo do Parque Bondinho Pão de Açucar com selo comemorativo de 110 anos na cor branca e fundo transparente"
                className="img-fluid"
                src={process.env.REACT_APP_SERVER_LOGO}
              ></img>
            </a>
            {/* Logo Site */}
          </div>

          {/* Horário Desktop */}
          <div  className="hours-function d-none d-sm-block w-100">
            <div>
              {/* {
                isNewHours
                  ?
                  <> */}
                    <OpeningHoursNew />
                  {/* </>
                  :
                  <>
                    <OpeningHours />
                    <OpeningHoursWeekend />
                  </>
              } */}
            </div>
          </div>
          {/* END:Horário Desktop */}

          {/* Flag Dropdown */}
          <div
            className="navbar-collapse d-none d-md-flex w-75"
            id="navbarSupportedContent"
          >
            <ul
              className="navbar-nav ml-auto"
              style={{ maxHeight: '80px', alignItems: 'center' }}
            >
              <li
                className="nav-item dropdown nav-item-c2"
                id="wrapper"
                ref={wrapperRef}
              >
                <div
                  className="nav-link text-primary text-center"
                  id="navbarDropdownLang"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    alt={
                      i18next.language === 'pt'
                        ? 'Bandeira nacional do Brasil'
                        : i18next.language === 'en'
                          ? 'Bandeira nacional do Reino Unido'
                          : i18next.language === 'es'
                            ? 'Bandeira nacional da Espanha'
                            : 'Bandeira nacional do Brasil'
                    }
                    src={
                      activeFlag[i18next.language] === undefined
                        ? activeFlag.pt
                        : activeFlag[i18next.language]
                    }
                    style={{ width: '45px', height: '30px', display: 'inline' }}
                  />
                  <span className="language-selected">
                    {i18next.language === 'pt'
                      ? t('navHeader.language.portugues')
                      : i18next.language === 'en'
                        ? t('navHeader.language.ingles')
                        : i18next.language === 'es'
                          ? t('navHeader.language.espanhol')
                          : t('navHeader.language.portugues')}
                  </span>
                </div>

                <div
                  className="dropdown-menu languages px-4 py-3 rounded"
                  aria-labelledby="navbarDropdownLang"
                  data-dropdown="navbarDropdownMinhaConta"
                  style={{ zIndex: 1040 }}
                >
                  <button
                    className={lng === 'pt' ? 'pt active' : 'pt'}
                    onClick={changeLanguage}
                    value="pt"
                  >
                    <img
                      id="port-lang"
                      alt="bandeira do brasil"
                      src={BRFlag}
                      style={{ width: '45px', height: '30px' }}
                    />
                    {t('navHeader.language.portugues')}
                  </button>
                  <button
                    className={lng === 'en' ? 'en active' : 'en'}
                    onClick={changeLanguage}
                    value="en"
                  >
                    <img
                      id="esp-lang"
                      alt="bandeira da espanha"
                      src={ENFlag}
                      style={{ width: '45px', height: '30px' }}
                    />
                    {t('navHeader.language.ingles')}
                  </button>
                  <button
                    className={lng === 'es' ? 'es active' : 'es'}
                    onClick={changeLanguage}
                    value="es"
                  >
                    <img
                      id="ingl-lang"
                      alt="bandeira do reino unido"
                      src={ESFlag}
                      style={{ width: '45px', height: '30px' }}
                    />
                    {t('navHeader.language.espanhol')}
                  </button>
                </div>
              </li>

              <li className="nav-item active nav-item-c2 d-none">
                <a className="nav-link text-primary text-center" href="/">
                  <i className="fal fa-home fa-lg" aria-hidden="true"></i>
                </a>
              </li>

              {/* Flag Dropdown */}

              {/* User Dropdown */}
              <li
                className="nav-item dropdown nav-item-c2"
                data-nav="conta"
                onClick={keepDropdownOpen}
              >
                {auth === '{}' || auth === 'null' ? (
                  <>
                    <div
                      className="nav-link text-primary text-center"
                      id="navbarDropdownMinhaConta"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-label="Login"
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'user']}
                        size="2x"
                        style={{ color: '#ffffff', width: '20px' }}
                      />
                    </div>

                    <div
                      className="dropdown-menu my-account px-4 py-3 rounded"
                      id="authLoginDropdown"
                      aria-labelledby="navbarDropdownMinhaConta"
                      data-dropdown="navbarDropdownMinhaConta"
                      style={{ zIndex: 1040 }}
                    >
                      <h4 className="text-primary mb-3">
                        {t('navHeader.login.subTitle')}
                      </h4>
                      <AuthLogin />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="nav-link text-primary text-center"
                      id="navbarDropdownMinhaConta"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {JSON.parse(auth).userModel.photo === null ? (
                        <FontAwesomeIcon
                          icon={['fad', 'user-circle']}
                          size="2x"
                          style={{ color: 'white' }}
                        />
                      ) : (
                        <i>
                          <img
                            src={JSON.parse(auth).userModel.photo}
                            className="loggedIn-profile-pic"
                            alt="icon-user"
                            referrerPolicy="no-referrer"
                          />
                        </i>
                      )}
                    </div>

                    <div
                      className="dropdown-menu my-account logged-in px-4 py-3 rounded"
                      aria-labelledby="navbarDropdownMinhaConta"
                      data-dropdown="navbarDropdownMinhaConta"
                      style={{ zIndex: 1040 }}
                    >
                      <a
                        href="/perfil"
                        style={{
                          display: 'block',
                          color: 'black',
                          textDecoration: 'none',
                        }}
                      >
                        {t('navHeader.login.title')}
                      </a>
                      <a
                        href="/minhas-atividades"
                        style={{
                          display: 'block',
                          color: 'black',
                          textDecoration: 'none',
                        }}
                      >
                        {t('navHeader.login.myActivities')}
                      </a>
                      <a
                        href="/"
                        style={{
                          display: 'block',
                          color: 'black',
                          textDecoration: 'none',
                        }}
                        onClick={logOut}
                      >
                        {t('navHeader.login.leave')}
                      </a>
                    </div>
                  </>
                )}
              </li>
              {/* User Dropdown */}
              <CartHeader></CartHeader>
            </ul>
          </div>
        </div>
        {/* Horário Mobile */}
        <div className="hours-function justify-content-around d-sm-none my-0 mx-2 w-100">
          {/* {
            isNewHours
              ?
              <> */}
                <OpeningHoursNew />
              {/* </>
              :
              <>
                <OpeningHours />
                <OpeningHoursWeekend />
              </>
          } */}
        </div>
        {/* END:Horário Mobile */}
      </nav>
    </>
  );
};

export default NavHeader;
