import React from "react";

function ProgramOngsComponentES() {
    return (
        <>
            <div className="programOng">
                <h1>Programa de Incentivos para Organizaciones No Gubernamentales (ONG)</h1>

                <h2>1. OBJETIVO</h2>
                <br /><p>Establecer directrices para el Programa de Incentivos para Organizaciones No Gubernamentales (ONG), con el objetivo de garantizar la transparencia y la equidad en la concesión de descuentos diferenciados para la compra de entradas al Parque Bondinho Pão de Açúcar®, para uso exclusivo de los beneficiarios de las entidades sociales seleccionadas.</p>

                <h2>2. ALCANCE</h2>
                <br /><p>Se aplica a todas las organizaciones no gubernamentales (ONG) debidamente constituidas y legalizadas durante al menos 02 (dos) años, contados a partir de la fecha de su registro en el Registro Nacional de Personas Jurídicas (CNPJ).</p>

                <h2>3. REFERENCIAS Y DOCUMENTOS COMPLEMENTARIOS</h2>
                <ul>
                    <li>PC.01-003 – Política Anticorrupción de Iter</li>
                    <li>Código de Conducta y Ética para Terceros de Iter</li>
                    <li>LGPD – Ley General de Protección de Datos</li>
                    <li>Ley Nº 12.813/2013 – Ley de Conflicto de Intereses</li>
                    <li>Ley Nº 12.846/2016 y Decreto Nº 11.129/2022 – Ley Anticorrupción</li>
                    <li>PC-389 Política Comercial para Canales de Taquilla</li>
                </ul>

                <h2>4. SIGLAS Y DEFINICIONES</h2>
                <ul>
                    <li><strong>ONG:</strong> Organizaciones No Gubernamentales</li>
                    <li><strong>Conflicto de Intereses:</strong> Ocurre cuando, debido a intereses personales o de terceros contrarios a los de la empresa o la ley, un empleado puede ser influenciado para actuar en contra de los principios o intereses de la empresa, tomando una decisión inapropiada o incumpliendo algunas de sus responsabilidades profesionales.</li>
                    <li><strong>Ventaja Indebida:</strong> Se refiere a todas las concesiones indebidas de cualquier cosa de valor y los pagos impropios realizados en un contexto empresarial, incluyendo la oferta, promesa, pago o entrega indebida de cualquier cosa de valor a un funcionario público o representante de la administración pública, ya sea una persona física o jurídica, directa o indirectamente.</li>
                </ul>

                <h2>5. DIRECTRICES</h2>

                <h3>5.1 Principios Generales</h3>
                <ul>
                    <li><strong>Integridad:</strong> Todas las acciones deben cumplir con las normas éticas y legales internas y externas pertinentes.</li>
                    <li><strong>Transparencia:</strong> Todas las interacciones deben llevarse a cabo con claridad y honestidad.</li>
                    <li><strong>Equidad:</strong> Todas las partes interesadas deben ser tratadas de manera justa.</li>
                    <li><strong>Colaboración:</strong> Las acciones deben promover la cooperación mutua.</li>
                </ul>

                <h3>5.2 Programa de Incentivos para Organizaciones No Gubernamentales (ONG)</h3>
                <br /><p>El Programa de Incentivos para Organizaciones No Gubernamentales (ONG) consiste en una iniciativa para seleccionar ONG y otorgar descuentos diferenciados en la compra de entradas al Parque Bondinho Pão de Açúcar®, para uso exclusivo de los beneficiarios atendidos por las entidades seleccionadas, en actividades sin fines de lucro, con el objetivo de apoyar acciones de carácter social y contribuir a la generación de un impacto positivo.</p>

                <br /><p>5.2.1 Condiciones de Participación</p>
                <ol>
                    <li>Podrán participar en este Programa de Incentivos las organizaciones que estén debidamente constituidas y legalizadas durante al menos 02 (dos) años, contado a partir de la fecha de su registro en el Registro Nacional de Personas Jurídicas (CNPJ).</li>
                    <li>Todas las comunicaciones relacionadas con el proceso de selección, así como el resultado de la selección, se divulgarán a través del correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>El cumplimiento de las condiciones y requisitos previos de las organizaciones no gubernamentales (ONG) se verificará durante el proceso de selección, mediante la presentación de los documentos obligatorios indicados en este Programa.</li>
                    <li>La falta de envío o el retraso en la presentación de los documentos requeridos eliminará automáticamente a la organización del proceso de selección.</li>
                    <li>No serán aceptadas en este Programa las organizaciones que tengan, entre sus directores estatutarios, empleados del Parque Bondinho Pão de Açúcar®, sus cónyuges o parientes hasta el segundo grado.</li>
                    <li>La participación en el proceso de selección también implica la responsabilidad personal e intransferible de las organizaciones y/o sus representantes legales con respecto a la veracidad de la información proporcionada al Parque Bondinho Pão de Açúcar® y al Grupo Iter, la holding que administra el Parque Bondinho.</li>
                </ol>

                <br /><p>5.2.2 Proceso de Inscripción</p>
                <ol>
                    <li>La inscripción en el proceso de selección debe realizarse exclusivamente a través del correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, junto con el envío de los documentos obligatorios. Todos los materiales de orientación y plantillas se proporcionarán a través del correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>Solo se considerarán válidas las inscripciones que cumplan con los requisitos de este Programa.</li>
                    <li>La organización social autoriza que todos los datos e información proporcionados sean almacenados y utilizados por el Parque Bondinho Pão de Açúcar® para el propósito de la inscripción en este Programa, así como compartidos con las autoridades competentes para cumplir con este propósito, de acuerdo con la Ley Nº 13.709/2018 - Ley General de Protección de Datos (LGPD).</li>
                    <li>Durante todo el proceso de selección, cualquier duda de las organizaciones debe enviarse al correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>La organización social será la única responsable de la autoría de la inscripción y será responsable, incluso judicialmente, en caso de cualquier reclamación por parte de terceros con respecto a la información proporcionada al Parque Bondinho Pão de Açúcar® y al Grupo Iter.</li>
                </ol>

                <br /><p>5.2.3 Selección</p>
                <ol>
                    <li>La selección de la organización no gubernamental (ONG) será realizada por un comité compuesto por empleados del Parque Bondinho Pão de Açúcar® y del Grupo Iter.</li>
                    <li>El proceso de selección de las organizaciones no gubernamentales (ONG), dirigido por el Comité Técnico, consistirá en las siguientes etapas de evaluación, todas eliminatorias:
                        <ol type="a">
                            <li>Análisis Documental.</li>
                            <li>Evaluación de Cumplimiento.</li>
                            <li>Análisis de la información contenida en el formulario de inscripción (*) y alineación con la estrategia y los valores del Parque Bondinho Pão de Açúcar®.</li>
                            <li>Selección final de la organización social.</li>
                        </ol>
                        (*) las plantillas se proporcionarán a través del correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.
                    </li>
                    <li>Criterios de evaluación:
                        <ul>
                            <li>Alcance de los proyectos desarrollados por las organizaciones sociales.</li>
                            <li>Objetivos del proyecto y la visita.</li>
                            <li>Experiencia de la organización.</li>
                        </ul>
                    </li>
                    <li>La selección final de la organización social estará sujeta a la cantidad de entradas disponibles en el Programa de Incentivos en el momento del proceso de selección de la institución candidata. Anualmente, el Parque Bondinho destinará 5,000 entradas para el Programa, las cuales se utilizarán a lo largo del año, según la selección de organizaciones no gubernamentales (ONG).</li>
                    <li>Cada organización no gubernamental podrá utilizar hasta 1,000 entradas por año.</li>
                    <li>El Parque Bondinho podrá cambiar la cantidad de entradas disponibles mediante la actualización de este documento.</li>
                    <li>El descuento ofrecido a las organizaciones no gubernamentales (ONG) seleccionadas será del 20% en cualquier entrada.</li>
                    <li>La organización social seleccionada será la única y exclusivamente responsable de los recursos necesarios para la compra de las entradas y para la operacionalización de las visitas, incluyendo el transporte de los beneficiarios de la entidad.</li>
                    <li>La organización social seleccionada será la única y exclusivamente responsable del cumplimiento de las normas éticas y legales internas y externas pertinentes durante toda su relación con el Parque Bondinho Pão de Açúcar®.</li>
                    <li>El resultado final del proceso de selección se comunicará exclusivamente a través del correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, y el proceso se considerará concluido con la divulgación del resultado.</li>
                </ol>

                <br /><p>5.2.4 Documentos Requeridos</p>
                <ol>
                    <li>Las organizaciones no gubernamentales (ONG) deben presentar, al momento de la inscripción, los siguientes documentos obligatorios:
                        <ul>
                            <li>Tarjeta CNPJ;</li>
                            <li>Estatuto o Contrato Social actualizado, debidamente registrado en la notaría competente;</li>
                            <li>Declaración de Responsabilidad del Proponente, firmada por el representante legal; (*)</li>
                            <li>Formulario de Diligencia Debida para Patrocinios y Asociaciones, firmado por el representante legal de la institución; (*)</li>
                            <li>Formulario de Inscripción. (*)</li>
                        </ul>
                        (*) las plantillas se proporcionarán a través del correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.
                        (*) Reforzamos que todas las respuestas pueden estar sujetas a una diligencia debida para su verificación y, si es necesario, se podrán solicitar informaciones y documentos adicionales en cualquier momento.
                        (*) Si la inscripción se completa más de una vez, solo se considerará la última respuesta.
                    </li>
                    <li>El derecho al descuento diferenciado podrá ser revocado en cualquier momento, sin previo aviso, para cualquier organización social que viole las premisas de esta política y/o sea sospechosa o esté involucrada en situaciones de corrupción, fraude o actos ilícitos, reservándose el Parque Bondinho Pão de Açúcar® el derecho de investigar la información a través de todos los medios legales disponibles.</li>
                </ol>

                <br /><p>5.2.5 Concesión del Descuento Diferenciado</p>
                <ol>
                    <li>El descuento diferenciado que otorgará el Parque Bondinho Pão de Açúcar® está condicionado al cumplimiento de todos los requisitos de este Programa y se comunicará a la organización social seleccionada a través del correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, dentro de los 15 (quince) días hábiles posteriores a la recepción de toda la documentación obligatoria. Además, se proporcionarán orientaciones sobre los trámites y plazos para la compra de entradas.</li>
                    <li>Para el acceso de los beneficiarios de la organización social seleccionada al Parque Bondinho, será obligatorio, junto con la entrada, presentar documentación que acredite el vínculo con la institución (por ejemplo, una carta de autorización con los nombres de los visitantes firmada por el CNPJ y un documento con foto). Para las entradas promocionales (por ejemplo, Entrada Estado RJ y Entrada Promocional para Brasileños), también será obligatorio presentar un documento comprobatorio en el momento de su uso (por ejemplo, identificación o comprobante de residencia), así como para las entradas de media entrada, de acuerdo con las orientaciones en el sitio web del Parque Bondinho (<a className="linkOng" href="http://sac.bondinho.com.br">sac.bondinho.com.br</a>).</li>
                    <li>El uso de las entradas para reventa, redistribución comercial o cualquier actividad que implique explotación económica está estrictamente prohibido.</li>
                </ol>

                <br /><p>5.2.6 Cláusula Anticorrupción</p>
                <ol>
                    <li>Los proponentes, así como sus respectivos socios, administradores, ejecutivos, empleados, agentes, subcontratistas y apoderados, al inscribirse en el proceso de selección de este Programa, acuerdan que:
                        <ol type="i">
                            <li>Conocen las normas anticorrupción previstas en la legislación brasileña, incluyendo el Código Penal Brasileño, la Ley de Improbidad Administrativa – Ley Nº 8.429/1992 y la Ley Nº 12.846/2013 y sus reglamentos (en conjunto, "Leyes Anticorrupción") y se comprometen a cumplirlas fielmente, por sí mismos y/o en nombre de sus socios, administradores y colaboradores, así como a exigir su cumplimiento por parte de terceros contratados por ellos. También declaran que sus actividades están en conformidad con las Leyes Anticorrupción y que en los últimos 05 (cinco) años no han sido objeto de ninguna investigación, indagación o proceso administrativo o judicial relacionado con el incumplimiento de las Leyes Anticorrupción o el lavado de dinero y que sus actividades están en conformidad con las disposiciones legales mencionadas.</li>
                            <li>No ofrecerán ningún tipo de pago o beneficio personal, ya sea directa o indirectamente, a ningún funcionario público o individuo empleado, vinculado o asociado con cualquier entidad que tenga participación o control directo o indirecto del Poder Público, en cualquier nivel.</li>
                            <li>Reciben como apoyo financiero incentivado, valores consistentes con el valor de mercado de los productos y/o servicios, y no están recibiendo ningún valor o recurso para la realización de sobornos o cualquier otro acto que viole las disposiciones de esta cláusula.</li>
                            <li>Defenderán, indemnizarán y mantendrán indemne al Parque Bondinho Pão de Açúcar®, sus directores, funcionarios y empleados contra cualquier acción, gastos o responsabilidades que puedan surgir en relación con esta cláusula.</li>
                        </ol>
                    </li>
                </ol>

                <br /><p>5.2.7 Condiciones Generales</p>
                <ol>
                    <li>Las organizaciones no gubernamentales (ONG) seleccionadas asumen la responsabilidad total por cualquier reclamación de terceros que se sientan perjudicados por su participación en este proceso de selección.</li>
                    <li>Serán descalificadas y excluidas del proceso de selección las organizaciones no gubernamentales (ONG) que:
                        <ul>
                            <li>No cumplan con los requisitos obligatorios de este Programa;</li>
                            <li>Presenten proyectos o materiales que sean considerados ofensivos por el Comité Técnico;</li>
                            <li>Cometan cualquier tipo de fraude;</li>
                            <li>No presenten los documentos obligatorios;</li>
                            <li>No cumplan con las premisas del Programa.</li>
                        </ul>
                    </li>
                    <li>Cualquier duda, discrepancia o situación no prevista en este Programa será revisada y decidida por el Parque Bondinho Pão de Açúcar®, cuya decisión será inapelable.</li>
                    <li>El Parque Bondinho se reserva el derecho, a su exclusivo criterio, de modificar los términos de este Programa, publicando una versión actualizada en el sitio web <a className="linkOng" href="http://www.bondinho.com.br">www.bondinho.com.br</a>, siempre guiado por la legalidad y la transparencia.</li>
                    <li>Cualquier acto, contrato o compromiso realizado por la organización social con el propósito de participar en este proceso de selección es de su única y exclusiva responsabilidad.</li>
                    <li>Cualquier material de comunicación en el que se incluya o mencione la marca del Parque Bondinho Pão de Açúcar® deberá ser sometido a la aprobación previa de la empresa.</li>
                    <li>Las preguntas deben dirigirse exclusivamente al correo electrónico <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                </ol>

                <h2>6. CONTROL DE VERSIONES</h2>
                <br /><p>Este estándar entrará en vigencia a partir de la fecha de su publicación, revocando todas las disposiciones contrarias y versiones anteriores.</p>
            </div>
        </>

    )
}

export default ProgramOngsComponentES;