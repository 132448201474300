function ExperienceRulesEN() {
    return (
        <ul className="mt-4">
            <li><p>The experience starts at 4:15 AM and may have its schedule changed due to variations in sunrise. The schedule will be available on the ticket, and in case of any changes, customer service will contact you.</p></li>
            <li><p>Cancellation requests will not be refunded if made (i) after the date and time of boarding or the event; or (ii) after the experience has taken place.</p></li>
            <li><p>Cancellation requests for purchases made on the website must be submitted to customer service via email at <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a>.</p></li>
            <li><p>Rescheduling the experience is possible up to 7 days after the original date.</p></li>
            <li><p>In the event of abandonment or withdrawal from the experience after it has begun, there will be no right to a refund or compensation for unused services, as the operators and guides will have already prepared the entire contracted infrastructure.</p></li>
            <li><p>If the user and other individuals who purchased the Sunrise at Parque Bondinho experience do not show up at the scheduled time and location without prior notice, this will result in a "No-Show," meaning that no refunds will be issued.</p></li>
            <li><p>Access to the Clássico Sunset Club restaurant and the Bosque das Artes, both located on Sugarloaf Mountain, does not offer full accessibility and requires the use of stairs.</p></li>
            <li><p>The breakfast provided during the Sunrise at Parque Bondinho experience ends at 8:00 AM, but visitors may remain at the Clássico Sunset Club restaurant to consume the traditional menu (not included in the product) and also visit Parque Bondinho freely.</p></li>
            <li><p>There is no age restriction for this experience.</p></li>
            <li><p>Children under 3 years old can participate in the experience free of charge.</p></li>
            <li><p>In case of dietary restrictions, it is necessary to inform us at least 3 days in advance.</p></li>
        </ul>
    )
}

export default ExperienceRulesEN;