import React from "react";

function ProgramOngsComponentEN() {
    return (
        <>
            <div className="programOng">
                <h1>Incentive Program for Non-Governmental Organizations (NGOs)</h1>

                <h2>1. OBJECTIVE</h2>
                <br /><p>To establish guidelines for the Incentive Program for Non-Governmental Organizations (NGOs), aiming to ensure transparency and equity in granting differentiated discounts for the purchase of tickets to the Parque Bondinho Pão de Açúcar®, for the exclusive use of beneficiaries of selected social entities.</p>

                <h2>2. SCOPE</h2>
                <br /><p>Applies to all non-governmental organizations (NGOs) duly constituted and legalized for at least 02 (two) years, counting from the date of their registration in the National Register of Legal Entities (CNPJ).</p>

                <h2>3. REFERENCES AND COMPLEMENTARY DOCUMENTS</h2>
                <ul>
                    <li>PC.01-003 – Iter Anti-Corruption Policy</li>
                    <li>Iter Code of Conduct and Ethics for Third Parties</li>
                    <li>LGPD – General Data Protection Law</li>
                    <li>Law No. 12.813/2013 – Conflict of Interest Law</li>
                    <li>Law No. 12.846/2016 and Decree No. 11.129/2022 – Anti-Corruption Law</li>
                    <li>PC-389 Commercial Policy for Ticketing Channels</li>
                </ul>

                <h2>4. ACRONYMS AND DEFINITIONS</h2>
                <ul>
                    <li><strong>NGOs:</strong> Non-Governmental Organizations</li>
                    <li><strong>Conflict of Interest:</strong> Occurs when, due to personal or third-party interests contrary to those of the company or the law, an employee may be influenced to act against the principles or interests of the company, making an inappropriate decision or failing to fulfill some of their professional responsibilities.</li>
                    <li><strong>Undue Advantage:</strong> Refers to all undue grants of anything of value and improper payments made in a business context, including the offer, promise, payment, or undue delivery of anything of value to a public official or representative of public administration, whether an individual or legal entity, directly or indirectly.</li>
                </ul>

                <h2>5. GUIDELINES</h2>

                <h3>5.1 General Principles</h3>
                <ul>
                    <li><strong>Integrity:</strong> All actions must comply with relevant internal and external ethical and legal standards.</li>
                    <li><strong>Transparency:</strong> All interactions must be conducted with clarity and honesty.</li>
                    <li><strong>Equity:</strong> All stakeholders must be treated fairly.</li>
                    <li><strong>Collaboration:</strong> Actions should promote mutual cooperation.</li>
                </ul>

                <h3>5.2 Incentive Program for Non-Governmental Organizations (NGOs)</h3>
                <br /><p>The Incentive Program for Non-Governmental Organizations (NGOs) consists of an initiative to select NGOs to grant differentiated discounts on the purchase of tickets to the Parque Bondinho Pão de Açúcar®, for the exclusive use of beneficiaries served by the selected entities, in non-profit activities, aiming to support social actions and contribute to generating positive impact.</p>

                <br /><p>5.2.1 Participation Conditions</p>
                <ol>
                    <li>Organizations that have been duly constituted and legalized for at least 02 (two) years, counting from the date of their registration in the National Register of Legal Entities (CNPJ), may participate in this Incentive Program.</li>
                    <li>All communications regarding the selection process, as well as the selection result, will be disclosed via the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>The fulfillment of the conditions and prerequisites of the non-governmental organizations (NGOs) will be verified during the selection process, through the presentation of the mandatory documents indicated in this Program.</li>
                    <li>Failure to submit or delay in presenting the required documents will automatically eliminate the organization from the selection process.</li>
                    <li>Organizations that have, among their statutory directors, employees of Parque Bondinho Pão de Açúcar®, their spouses, or relatives up to the second degree will not be accepted in this Program.</li>
                    <li>Participation in the selection process also implies the personal and non-transferable responsibility of the organizations and/or their legal representatives regarding the accuracy of the information provided to Parque Bondinho Pão de Açúcar® and the Iter Group, the holding company that manages Parque Bondinho.</li>
                </ol>

                <br /><p>5.2.2 Registration Process</p>
                <ol>
                    <li>Registration for the selection process must be done exclusively via the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, along with the submission of the mandatory documents. All guidance materials and templates will be provided via the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>Only registrations that meet the requirements of this Program will be considered valid.</li>
                    <li>The social organization authorizes that all data and information provided will be stored and used by Parque Bondinho Pão de Açúcar® for the purpose of registration in this Program, as well as shared with competent authorities to fulfill this purpose, in accordance with Law No. 13.709/2018 - General Data Protection Law (LGPD).</li>
                    <li>During the entire selection process, any questions from the organizations should be sent to the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                    <li>The social organization will be solely responsible for the authorship of the registration and will be legally responsible, including in court, in case of any claims by third parties regarding the information provided to Parque Bondinho Pão de Açúcar® and the Iter Group.</li>
                </ol>

                <br /><p>5.2.3 Selection</p>
                <ol>
                    <li>The selection of the non-governmental organization (NGO) will be carried out by a committee composed of employees from Parque Bondinho Pão de Açúcar® and the Iter Group.</li>
                    <li>The selection process for non-governmental organizations (NGOs), conducted by the Technical Committee, will consist of the following evaluation stages, all of which are eliminatory:
                        <ol type="a">
                            <li>Documentary Analysis.</li>
                            <li>Compliance Assessment.</li>
                            <li>Analysis of the information contained in the registration form (*) and alignment with the strategy and values of Parque Bondinho Pão de Açúcar®.</li>
                            <li>Final selection of the social organization.</li>
                        </ol>
                        (*) templates will be provided via the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.
                    </li>
                    <li>Evaluation criteria:
                        <ul>
                            <li>Scope of the projects developed by the social organizations.</li>
                            <li>Objectives of the project and the visit.</li>
                            <li>Experience of the organization.</li>
                        </ul>
                    </li>
                    <li>The final selection of the social organization will be subject to the number of tickets available in the Incentive Program at the time of the selection process of the candidate institution. Annually, Parque Bondinho will allocate 5,000 tickets for the Program, which will be used throughout the year, according to the selection of non-governmental organizations (NGOs).</li>
                    <li>Each non-governmental organization may use up to 1,000 tickets per year.</li>
                    <li>Parque Bondinho may change the number of tickets available by updating this document.</li>
                    <li>The discount offered to selected non-governmental organizations (NGOs) will be 20% on any ticket.</li>
                    <li>The selected social organization will be solely and exclusively responsible for the resources needed to purchase the tickets and for the operationalization of the visits, including transportation of the beneficiaries of the entity.</li>
                    <li>The selected social organization will be solely and exclusively responsible for compliance with relevant internal and external ethical and legal standards during its entire relationship with Parque Bondinho Pão de Açúcar®.</li>
                    <li>The final result of the selection process will be communicated exclusively via the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, and the process will be considered concluded with the disclosure of the result.</li>
                </ol>

                <br /><p>5.2.4 Required Documents</p>
                <ol>
                    <li>Non-governmental organizations (NGOs) must submit, at the time of registration, the following mandatory documents:
                        <ul>
                            <li>CNPJ Card;</li>
                            <li>Updated Statute or Articles of Association, duly registered with the competent notary office;</li>
                            <li>Proponent's Declaration of Responsibility, signed by the legal representative; (*)</li>
                            <li>Sponsorship and Partnership Due Diligence Form, signed by the legal representative of the institution; (*)</li>
                            <li>Registration Form. (*)</li>
                        </ul>
                        (*) templates will be provided via the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.
                        (*) We emphasize that all responses may be subject to due diligence for verification, and if necessary, additional information and documents may be requested at any time.
                        (*) If the registration is filled out more than once, only the last response will be considered.
                    </li>
                    <li>The right to the differentiated discount may be revoked at any time, without prior notice, for any social organization that violates the premises of this policy and/or is suspected of or involved in situations of corruption, fraud, or illegal acts, with Parque Bondinho Pão de Açúcar® reserving the right to investigate the information through all available legal means.</li>
                </ol>

                <br /><p>5.2.5 Granting of Differentiated Discount</p>
                <ol>
                    <li>The differentiated discount to be granted by Parque Bondinho Pão de Açúcar® is conditional upon meeting all the requirements of this Program and will be communicated to the selected social organization via the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>, within 15 (fifteen) business days after receipt of all mandatory documentation. Additionally, guidelines regarding the procedures and deadlines for ticket purchase will be provided.</li>
                    <li>For access of the beneficiaries of the selected social organization to Parque Bondinho, it will be mandatory, along with the ticket, to present documentation proving the link with the institution (e.g., authorization letter with the names of the visitors signed by the CNPJ and a photo ID). For promotional tickets (e.g., State of RJ Ticket and Promotional Ticket for Brazilians), it will also be mandatory to present proof of eligibility at the time of use (e.g., ID or proof of residence), as well as for half-price tickets, in accordance with the guidelines on the Parque Bondinho website (<a className="linkOng" href="http://sac.bondinho.com.br">sac.bondinho.com.br</a>).</li>
                    <li>The use of tickets for resale, commercial redistribution, or any activity involving economic exploitation is strictly prohibited.</li>
                </ol>

                <br /><p>5.2.6 Anti-Corruption Clause</p>
                <ol>
                    <li>The proponents, as well as their respective partners, administrators, executives, employees, agents, subcontractors, and attorneys, upon registering for the selection process of this Program, agree that:
                        <ol type="i">
                            <li>They are aware of the anti-corruption norms provided for in Brazilian legislation, including the Brazilian Penal Code, the Administrative Improbity Law – Law No. 8.429/1992, and Law No. 12.846/2013 and its regulations (collectively, “Anti-Corruption Laws”) and commit to faithfully comply with them, on their own behalf and/or on behalf of their partners, administrators, and collaborators, as well as to require compliance by third parties contracted by them. They also declare that their activities are in compliance with the Anti-Corruption Laws and that in the last 05 (five) years they have not been subject to any investigation, inquiry, or administrative or judicial proceedings related to non-compliance with the Anti-Corruption Laws or money laundering and that their activities are in compliance with the aforementioned legal provisions.</li>
                            <li>They shall not offer any type of payment or any personal benefit, either directly or indirectly, to any public official or individual employed, linked, or associated with any entity that has direct or indirect participation or control by the Public Power, at any level.</li>
                            <li>They are receiving as incentivized financial support, values consistent with the market value of the products and/or services, and are not receiving any value or resources for the purpose of bribes or any other acts that violate the provisions of this clause.</li>
                            <li>They will defend, indemnify, and hold harmless Parque Bondinho Pão de Açúcar®, its directors, officers, and employees against any action, expenses, or liabilities that may arise in connection with this clause.</li>
                        </ol>
                    </li>
                </ol>

                <br /><p>5.2.7 General Conditions</p>
                <ol>
                    <li>The selected non-governmental organizations (NGOs) assume full responsibility for any claims by third parties who feel harmed by their participation in this selection process.</li>
                    <li>Non-governmental organizations (NGOs) that:
                        <ul>
                            <li>Do not meet the mandatory requirements of this Program;</li>
                            <li>Present projects or materials that are considered offensive by the Technical Committee;</li>
                            <li>Commit any type of fraud;</li>
                            <li>Do not submit the required documents;</li>
                            <li>Do not comply with the premises of the Program, will be disqualified and excluded from the selection process.</li>
                        </ul>
                    </li>
                    <li>Any doubts, disagreements, or situations not provided for in this Program will be reviewed and decided by Parque Bondinho Pão de Açúcar®, whose decision is final.</li>
                    <li>Parque Bondinho reserves the right, at its sole discretion, to change the terms of this Program, publishing an updated version on the website <a className="linkOng" href="http://www.bondinho.com.br">www.bondinho.com.br</a>, always guided by legality and transparency.</li>
                    <li>Any act, contract, or commitment made by the social organization for the purpose of participating in this selection process is its sole and exclusive responsibility.</li>
                    <li>Any communication material in which the Parque Bondinho Pão de Açúcar® brand is included or mentioned must be submitted for prior approval by the company.</li>
                    <li>Questions should be directed exclusively to the email <a className="linkOng" href="mailto:relacionamentocomunitario@bondinho.com.br">relacionamentocomunitario@bondinho.com.br</a>.</li>
                </ol>

                <h2>6. VERSION CONTROL</h2>
                <br /><p>This standard comes into effect from the date of its publication, revoking all contrary provisions and previous versions.</p>
            </div>
        </>

    )
}

export default ProgramOngsComponentEN;